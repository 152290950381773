const ReportNumber = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M2 14.2857V19.4286C2 20.8487 3.11929 22 4.5 22H19.5C20.8807 22 22 20.8487 22 19.4286V14.2857M2 14.2857L5.14168 5.66854C5.50759 4.6649 6.44039 4 7.4825 4H16.5175C17.5596 4 18.4924 4.6649 18.8583 5.66854L22 14.2857M2 14.2857H7L8.66667 16.0857H15.3333L17 14.2857H22'
        stroke='#8A92A6'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ReportNumber;
