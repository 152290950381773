import Lottie from 'react-lottie';

import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { useRecoilValue } from 'recoil';

import { navStatusState } from '@context/atom';

import loading2 from '@assets/loading_2.json';

import ReactPortal from '../ReactPortal';

type Props = {
  isOpen: boolean;
  color?: string;
  wrapperId?: string;
  isCenter?: boolean;
  onClose?: () => void;
};

const Loader = ({
  isOpen,
  wrapperId = 'react-portal-loader',
  isCenter = false,
}: Props) => {
  const navStatus = useRecoilValue(navStatusState);
  return (
    <ReactPortal wrapperId={wrapperId}>
      <AnimatePresence>
        {isOpen && (
          <div
            className={classNames(
              'fixed inset-0 size-full top-[4.5rem] z-[300] ',
              {
                'left-[123px]': navStatus && !isCenter,
                'left-[40px]': !navStatus && !isCenter,
              },
            )}
          >
            <div className='flex items-center justify-center size-full'>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: loading2,
                }}
                width={80}
                height={80}
              />
            </div>
          </div>
        )}
      </AnimatePresence>
    </ReactPortal>
  );
};

export default Loader;
