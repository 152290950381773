import { useQuery } from '@tanstack/react-query';

import { getOrganizationMy } from '@services/toucheck/apiClient_toucheck';

const useGetOrganization = () => {
  const fetchApi = async () => {
    const data = await getOrganizationMy();
    return data;
  };
  return useQuery({
    queryKey: ['toucheck', 'organization'],
    queryFn: fetchApi,
    gcTime: 5 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
  });
};

export default useGetOrganization;
