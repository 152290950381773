const EmergencyIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='140'
      height='140'
      viewBox='0 0 140 140'
      fill='none'
    >
      <path
        d='M70 35C59.5608 35.0121 49.5527 39.1644 42.1711 46.5461C34.7894 53.9277 30.6371 63.9358 30.625 74.375V105C30.625 106.16 31.0859 107.273 31.9064 108.094C32.7269 108.914 33.8397 109.375 35 109.375H105C106.16 109.375 107.273 108.914 108.094 108.094C108.914 107.273 109.375 106.16 109.375 105V74.375C109.363 63.9358 105.211 53.9277 97.8289 46.5461C90.4473 39.1644 80.4392 35.0121 70 35Z'
        fill='#FF5E57'
      />
      <path
        d='M108.588 107.538C108.17 108.099 107.63 108.556 107.008 108.875C106.386 109.193 105.699 109.365 105 109.375H35C33.8407 109.372 32.73 108.91 31.9102 108.09C31.0905 107.27 30.6285 106.16 30.625 105V74.3754C30.6281 68.0946 32.135 61.9058 35.0197 56.3266C37.9044 50.7475 42.0831 45.9401 47.2062 42.3066C58.3364 71.2486 80.3753 94.6698 108.588 107.538Z'
        fill='#D32F2F'
      />
      <path
        d='M70 30.625C68.8397 30.625 67.7269 30.1641 66.9064 29.3436C66.0859 28.5231 65.625 27.4103 65.625 26.25V17.5C65.625 16.3397 66.0859 15.2269 66.9064 14.4064C67.7269 13.5859 68.8397 13.125 70 13.125C71.1603 13.125 72.2731 13.5859 73.0936 14.4064C73.9141 15.2269 74.375 16.3397 74.375 17.5V26.25C74.375 27.4103 73.9141 28.5231 73.0936 29.3436C72.2731 30.1641 71.1603 30.625 70 30.625Z'
        fill='#FFEA83'
      />
      <path
        d='M32.8766 43.4375L26.6901 37.251C25.8697 36.4306 25.4088 35.3179 25.4088 34.1577C25.4088 32.9975 25.8697 31.8849 26.6901 31.0645C27.5105 30.2441 28.6231 29.7832 29.7833 29.7832C30.9435 29.7832 32.0562 30.2441 32.8766 31.0645L39.0631 37.251C39.8835 38.0714 40.3444 39.184 40.3444 40.3442C40.3444 41.5044 39.8835 42.6171 39.0631 43.4375C38.2427 44.2579 37.13 44.7188 35.9698 44.7188C34.8097 44.7188 33.697 44.2579 32.8766 43.4375ZM21.875 78.7494H13.125C11.9647 78.7494 10.8519 78.2885 10.0314 77.468C9.21094 76.6475 8.75 75.5347 8.75 74.3744C8.75 73.2141 9.21094 72.1013 10.0314 71.2808C10.8519 70.4603 11.9647 69.9994 13.125 69.9994H21.875C23.0353 69.9994 24.1481 70.4603 24.9686 71.2808C25.7891 72.1013 26.25 73.2141 26.25 74.3744C26.25 75.5347 25.7891 76.6475 24.9686 77.468C24.1481 78.2885 23.0353 78.7494 21.875 78.7494Z'
        fill='#EDD25F'
      />
      <path
        d='M100.937 43.4375C100.531 43.0314 100.208 42.5491 99.9883 42.0184C99.7683 41.4876 99.6551 40.9188 99.6551 40.3442C99.6551 39.7697 99.7683 39.2008 99.9883 38.6701C100.208 38.1393 100.531 37.6571 100.937 37.251L107.123 31.0645C107.944 30.2441 109.056 29.7832 110.217 29.7832C111.377 29.7832 112.49 30.2441 113.31 31.0645C114.13 31.8849 114.591 32.9975 114.591 34.1577C114.591 35.3179 114.13 36.4306 113.31 37.251L107.123 43.4375C106.717 43.8439 106.235 44.1662 105.704 44.3861C105.174 44.6061 104.605 44.7193 104.03 44.7193C103.456 44.7193 102.887 44.6061 102.356 44.3861C101.825 44.1662 101.343 43.8439 100.937 43.4375ZM126.875 78.7494H118.125C116.965 78.7494 115.852 78.2885 115.031 77.468C114.211 76.6475 113.75 75.5347 113.75 74.3744C113.75 73.2141 114.211 72.1013 115.031 71.2808C115.852 70.4603 116.965 69.9994 118.125 69.9994H126.875C128.035 69.9994 129.148 70.4603 129.969 71.2808C130.789 72.1013 131.25 73.2141 131.25 74.3744C131.25 75.5347 130.789 76.6475 129.969 77.468C129.148 78.2885 128.035 78.7494 126.875 78.7494Z'
        fill='#FFEA83'
      />
      <path
        d='M78.75 70H74.375V65.625C74.375 64.4647 73.9141 63.3519 73.0936 62.5314C72.2731 61.7109 71.1603 61.25 70 61.25C68.8397 61.25 67.7269 61.7109 66.9064 62.5314C66.0859 63.3519 65.625 64.4647 65.625 65.625V70H61.25C60.0897 70 58.9769 70.4609 58.1564 71.2814C57.3359 72.1019 56.875 73.2147 56.875 74.375C56.875 75.5353 57.3359 76.6481 58.1564 77.4686C58.9769 78.2891 60.0897 78.75 61.25 78.75H65.625V83.125C65.625 84.2853 66.0859 85.3981 66.9064 86.2186C67.7269 87.0391 68.8397 87.5 70 87.5C71.1603 87.5 72.2731 87.0391 73.0936 86.2186C73.9141 85.3981 74.375 84.2853 74.375 83.125V78.75H78.75C79.9103 78.75 81.0231 78.2891 81.8436 77.4686C82.6641 76.6481 83.125 75.5353 83.125 74.375C83.125 73.2147 82.6641 72.1019 81.8436 71.2814C81.0231 70.4609 79.9103 70 78.75 70Z'
        fill='#DB2626'
      />
      <path
        d='M118.125 100.625H21.875C19.4588 100.625 17.5 102.584 17.5 105V122.5C17.5 124.916 19.4588 126.875 21.875 126.875H118.125C120.541 126.875 122.5 124.916 122.5 122.5V105C122.5 102.584 120.541 100.625 118.125 100.625Z'
        fill='#D7E9F9'
      />
      <path
        d='M74.2875 83.825C74.1368 84.8519 73.6197 85.7896 72.8317 86.4651C72.0436 87.1405 71.0379 87.5081 70 87.5C68.8407 87.4965 67.73 87.0345 66.9102 86.2148C66.0905 85.395 65.6285 84.2843 65.625 83.125V78.75H61.25C60.0897 78.75 58.9769 78.2891 58.1564 77.4686C57.3359 76.6481 56.875 75.5353 56.875 74.375C56.875 73.2147 57.3359 72.1019 58.1564 71.2814C58.9769 70.4609 60.0897 70 61.25 70H62.3438C65.956 74.9146 69.9496 79.5372 74.2875 83.825Z'
        fill='#A31616'
      />
      <path
        d='M122.5 112.875V122.5C122.497 123.659 122.034 124.77 121.215 125.59C120.395 126.409 119.284 126.872 118.125 126.875H21.875C20.7157 126.872 19.605 126.409 18.7852 125.59C17.9655 124.77 17.5035 123.659 17.5 122.5V105C17.5035 103.841 17.9655 102.73 18.7852 101.91C19.605 101.091 20.7157 100.628 21.875 100.625H95.6375C104.037 105.829 113.064 109.945 122.5 112.875Z'
        fill='#B3CFE5'
      />
    </svg>
  );
};

export default EmergencyIcon;
