import { Navigate, Outlet } from 'react-router-dom';

import Cookies from 'js-cookie';

import { TOKEN } from '@constants/auth';

const ProtectedRoute = () => {
  const user = Cookies.get(TOKEN.TOUCHSIREN_TOKEN);
  const relief = Cookies.get(TOKEN.RELIEFMAP_TOKEN);
  const monitordog = Cookies.get(TOKEN.MONITORDOG_TOKEN);
  const toucheck = Cookies.get(TOKEN.TOUCHECK_TOKEN);
  return user || relief || monitordog || toucheck ? (
    <Outlet />
  ) : (
    <Navigate to='/user/login' />
  );
};

export default ProtectedRoute;
