import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useSetRecoilState } from 'recoil';

import {
  getAccountForMonitordog,
  getOrgForMonitordog,
} from '@services/apiClient';

import { monitordogAccountState, monitordogOrgState } from '@context/atom';

import { TOKEN } from '@constants/auth';

const useLoginForMonitordog = () => {
  const queryClient = useQueryClient();
  const setMonitordogUser = useSetRecoilState(monitordogAccountState);
  const setMonitordogState = useSetRecoilState(monitordogOrgState);
  const onLoginForMonitordog = async (token: string) => {
    Cookies.set(TOKEN.MONITORDOG_TOKEN, token);

    const org = await getOrgForMonitordog();
    const userData = await getAccountForMonitordog();

    setMonitordogUser(userData);
    setMonitordogState(org);

    queryClient.prefetchQuery({
      queryKey: ['monitordog', 'organization', 'my'],
      queryFn: getOrgForMonitordog,
    });
    queryClient.prefetchQuery({
      queryKey: ['monitordog', 'account', 'me'],
      queryFn: getAccountForMonitordog,
    });
  };
  return { onLoginForMonitordog };
};

export default useLoginForMonitordog;
