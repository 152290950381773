const CloseIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
    >
      <path
        d='M5 5L25 25'
        stroke='#8A92A6'
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M5 25L25 5'
        stroke='#8A92A6'
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default CloseIcon;
