import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { appNameState, appState, userState } from '@context/atom';

import useGetNotifications from '@hooks/useGetNotifications';
import useReadNotification from '@hooks/useReadNotification';

import HeaderBell from '@assets/HeaderBell';
import ProfileIcon from '@assets/ProfileIcon';
import HeaderBellForCheck from '@assets/toucheck/HeaderBellForCheck';

import AlarmDialog from './popup/dialog/AlarmDialog';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasUnRead, setHasUnRead] = useState(false);

  const appName = useRecoilValue(appNameState);

  const appStateValue = useRecoilValue(appState);

  const userStateValue = useRecoilValue(userState);

  const { data: notifications } = useGetNotifications({
    page: 1,
    per_page: 100,
    enabled: Boolean(appName === 'touchsiren'),
  });
  const { mutate } = useReadNotification();

  const onClickAlarm = () => {
    setIsOpen(true);
    const ids =
      notifications?.filter((item) => !item.is_read).map((item) => item.id) ||
      [];
    if (ids.length > 0) {
      mutate({ ids });
    }
  };

  useEffect(() => {
    if (notifications) {
      const filteredNotifications = notifications.filter(
        (item) => !item.is_read,
      );
      setHasUnRead(filteredNotifications.length > 0);
    }
  }, [notifications]);

  return (
    <>
      <header className='flex items-center justify-end w-full h-[4.5rem] px-10 text-right bg-white shrink-0'>
        <div className='flex items-center gap-6 '>
          {appName === 'touchsiren' && (
            <div className='relative cursor-pointer' onClick={onClickAlarm}>
              <HeaderBell />
              {hasUnRead && (
                <div className='absolute w-2 h-2 rounded-full -top-1 -right-1 bg-text_red' />
              )}
            </div>
          )}
          {appName === 'toucheck' && (
            <Link
              to={`https://fierce-passenger-e82.notion.site/1cd8433c6b12450b92b09c5d8115cca4`}
              target='_blank'
              className='relative cursor-pointer'
              onClick={onClickAlarm}
            >
              <HeaderBellForCheck />
              {hasUnRead && (
                <div className='absolute w-2 h-2 rounded-full -top-0 -left-3 bg-text_red' />
              )}
            </Link>
          )}

          {appName && (
            <div className='flex items-center'>
              <Link to={`/${appName}/settings/mypage`}>
                <ProfileIcon />
              </Link>
              <div className='w-2.5' />
              <div className='flex flex-col gap-0.5 text-left'>
                {appStateValue?.name && (
                  <span className='text-sm font-bold text-text_primary max-w-[100px] truncate'>
                    {appStateValue?.name || '소속 없음'}
                  </span>
                )}
                <span className='text-xs text-text_gray'>
                  {userStateValue?.name || '이름 없음'}
                </span>
              </div>
            </div>
          )}
          {/* <div className='flex items-center gap-2.5'>
            <button
              onClick={() => i18n.changeLanguage('ko')}
              className={`${lng === 'ko' ? 'text-black' : 'text-[#ccc]'} font-bold`}
            >
              KR
            </button>
            <span className='font-bold text-[#ccc]'>|</span>
            <button
              onClick={() => i18n.changeLanguage('en')}
              className={`${lng === 'en' ? 'text-black' : 'text-[#ccc]'} font-bold`}
            >
              EN
            </button>
          </div> */}
        </div>
      </header>
      {notifications && (
        <AlarmDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          data={notifications}
        />
      )}
    </>
  );
};

export default Header;
