import { getURLSearchParams } from '@utils/index';

const useCurPage = () => {
  const location = window.location.search;

  const curPage = getURLSearchParams(location, 'page');

  return { curPage };
};

export default useCurPage;
