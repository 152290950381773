import { useTranslation } from 'react-i18next';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { deleteFacilityForIdForToucheck } from '@services/toucheck/apiClient_toucheck';

import useToast from '@hooks/useToast';

const useDeleteFacility = (uuid: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { notify } = useToast();
  const fetchApi = async () => {
    const data = await deleteFacilityForIdForToucheck(uuid);
    return data;
  };
  return useMutation({
    mutationFn: fetchApi,
    onSuccess: () => {
      notify({
        type: 'success',
        title: t('integrate_success'),
        content: t('facility_delete_success'),
      });
      queryClient.invalidateQueries({ queryKey: ['toucheck', 'facilities'] });
    },
    onError: (e) => {
      if (e instanceof AxiosError)
        notify({
          type: 'error',
          title: t('facility_delete_title'),
          content: e.response?.data?.error.message,
        });
    },
  });
};

export default useDeleteFacility;
