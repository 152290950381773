import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Lottie from 'react-lottie';

import loading2 from '@assets/loading_2.json';

const DefaultFallback = ({ children }: { children: React.ReactNode }) => {
  return (
    <ErrorBoundary fallbackRender={() => <div></div>}>
      <Suspense
        fallback={
          <div className='flex items-center justify-center min-h-screen size-full'>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: loading2,
              }}
              width={80}
              height={80}
            />
          </div>
        }
      >
        {children}
      </Suspense>
    </ErrorBoundary>
  );
};

export default DefaultFallback;
