const ArrowBottomIcon = ({ stroke = '#8A92A6' }: { stroke?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M12.75 5.75L8.25 10.25L3.75 5.75'
        stroke={stroke}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ArrowBottomIcon;
