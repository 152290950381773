const CheckManagerIcon = ({ fill }: { fill: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M15.4989 2.92969C13.0136 2.92969 10.9989 4.94469 10.9989 7.42969C10.9989 9.91469 13.0136 11.9297 15.4989 11.9297C17.9842 11.9297 19.9989 9.91469 19.9989 7.42969C19.9989 4.94469 17.9842 2.92969 15.4989 2.92969Z'
        fill={fill}
      />
      <g opacity='0.4'>
        <path
          d='M3.99902 7.92969C3.99902 5.72069 5.78992 3.92969 7.99902 3.92969C8.60042 3.92969 9.18463 4.05269 9.71653 4.30469C8.78522 6.26369 8.54302 8.69572 10.2518 11.2357C9.58552 11.7017 8.82842 11.9297 7.99902 11.9297C5.78992 11.9297 3.99902 10.1387 3.99902 7.92969Z'
          fill={fill}
        />
        <path
          d='M1.99902 17.9297C1.99902 15.8857 3.22742 14.0307 5.06152 13.2737C5.31732 13.1677 5.59092 13.1897 5.84282 13.3047C6.09972 13.4217 6.17752 13.4667 6.31152 13.5237C6.86642 13.7597 7.62273 13.9127 8.25292 13.9177C7.91002 14.3157 7.03452 15.8257 7.00202 17.9427C6.98662 18.9397 6.91782 20.1717 7.15182 20.9317L2.99902 20.9297C2.44672 20.9297 1.99902 20.4817 1.99902 19.9297V17.9297Z'
          fill={fill}
        />
      </g>
      <path
        opacity='0.4'
        d='M8.99902 17.9296C8.99902 15.8856 10.2274 14.0306 12.0615 13.2736C12.3173 13.1676 12.5909 13.1896 12.8428 13.3046C13.7588 13.7216 14.6238 13.9296 15.499 13.9296C16.3772 13.9296 17.2392 13.7326 18.124 13.3356C18.3796 13.2216 18.6793 13.2246 18.9365 13.3356C20.7638 14.1236 21.999 15.9336 21.999 17.9296V19.9296C21.999 20.4816 21.5513 20.9296 20.999 20.9296H9.99902C9.44672 20.9296 8.99902 20.4816 8.99902 19.9296V17.9296Z'
        fill={fill}
      />
    </svg>
  );
};

export default CheckManagerIcon;
