import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  appNameState,
  monitordogAccountState,
  navStatusState,
  reliefmapAccountState,
  toucheckAccountState,
  touchsirenAccountState,
} from '@context/atom';

import ArrowBottomIcon from '@assets/ArrowBottomIcon';
import LogoIcon from '@assets/logo.png';
import LogoText from '@assets/logo_text.png';
import NavDashboard from '@assets/NavDashboard';
import NavDotIcon from '@assets/NavDotIcon';
import NavInspection from '@assets/NavInspection';
import NavInstall from '@assets/NavInstall';
import NavReport from '@assets/NavReport';
import NavSettings from '@assets/NavSettings';
import CheckIssueIcon from '@assets/toucheck/nav/CheckIssueIcon';
import CheckManagerIcon from '@assets/toucheck/nav/CheckManagerIcon';
import CheckNavIcon from '@assets/toucheck/nav/CheckNavIcon';
import ServiceGuideIcon from '@assets/toucheck/ServiceGuideIcon';

import NavItem from './NavItem';

type NavType = {
  name: string;
  icon: (fill: string) => JSX.Element;
  path: string;
  link: string;
  children?: NavType[];
};

const SideBar = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const [navStatus, setNavStatus] = useRecoilState(navStatusState);

  const touchsirenValue = useRecoilValue(touchsirenAccountState);
  const reliefValue = useRecoilValue(reliefmapAccountState);
  const monitordogValue = useRecoilValue(monitordogAccountState);
  const toucheckValue = useRecoilValue(toucheckAccountState);

  const appName = useRecoilValue(appNameState);

  const SETTING_NAV_TOUCHSIREN: NavType[] = [
    {
      name: t('nav_setting'),
      icon: (fill: string) => <NavSettings fill={fill} />,
      path: '/touchsiren/settings',
      link: '/touchsiren/settings',
      children: [
        {
          name: t('nav_setting_mypage'),
          path: '/touchsiren/settings/mypage',
          link: '/touchsiren/settings/mypage',
          icon: (fill: string) => <NavDotIcon fill={fill} />,
        },
        {
          name: t('nav_setting_report_type'),
          path: '/touchsiren/settings/report-types',
          link: '/touchsiren/settings/report-types',
          icon: (fill: string) => <NavDotIcon fill={fill} />,
        },
        {
          name: t('nav_setting_spots'),
          path: '/touchsiren/settings/spots',
          link: '/touchsiren/settings/spots',
          icon: (fill: string) => <NavDotIcon fill={fill} />,
        },
        {
          name: t('nav_setting_members'),
          path: '/touchsiren/settings/members',
          link: '/touchsiren/settings/members',
          icon: (fill: string) => <NavDotIcon fill={fill} />,
        },
      ],
    },
  ];
  const SETTING_NAV_RELIEF: NavType[] = [
    {
      name: t('nav_setting'),
      icon: (fill: string) => <NavSettings fill={fill} />,
      path: '/reliefmap/settings',
      link: '/reliefmap/settings',
      children: [
        {
          name: t('nav_setting_mypage'),
          path: '/reliefmap/settings/mypage',
          link: '/reliefmap/settings/mypage',
          icon: (fill: string) => <NavDotIcon fill={fill} />,
        },

        {
          name: t('nav_setting_members'),
          path: '/reliefmap/settings/members',
          link: '/reliefmap/settings/members',
          icon: (fill: string) => <NavDotIcon fill={fill} />,
        },
      ],
    },
  ];

  const SETTING_MONITORDOG: NavType[] = [
    {
      name: t('nav_setting'),
      icon: (fill: string) => <NavSettings fill={fill} />,
      path: '/monitordog/settings',
      link: '/monitordog/settings',
      children: [
        {
          name: t('nav_setting_mypage'),
          path: '/monitordog/settings/mypage',
          link: '/monitordog/settings/mypage',
          icon: (fill: string) => <NavDotIcon fill={fill} />,
        },

        {
          name: t('nav_setting_members'),
          path: '/monitordog/settings/members',
          link: '/monitordog/settings/members',
          icon: (fill: string) => <NavDotIcon fill={fill} />,
        },
      ],
    },
  ];
  const SETTING_TOUCHECK: NavType[] = [
    {
      name: t('nav_setting'),
      icon: (fill: string) => <NavSettings fill={fill} />,
      path: '/toucheck/settings',
      link: '/toucheck/settings',
      children:
        toucheckValue?.grade === 'admin'
          ? [
              {
                name: t('nav_setting_coporation'),
                path: '/toucheck/settings/coporation',
                link: '/toucheck/settings/coporation',
                icon: (fill: string) => <NavDotIcon fill={fill} />,
              },
              {
                name: t('nav_setting_mypage'),
                path: '/toucheck/settings/mypage',
                link: '/toucheck/settings/mypage',
                icon: (fill: string) => <NavDotIcon fill={fill} />,
              },
              {
                name: t('check_subscribe'),
                path: '/toucheck/settings/subscribe',
                link: '/toucheck/settings/subscribe',
                icon: (fill: string) => <NavDotIcon fill={fill} />,
              },
            ]
          : [
              {
                name: t('nav_setting_mypage'),
                path: '/toucheck/settings/mypage',
                link: '/toucheck/settings/mypage',
                icon: (fill: string) => <NavDotIcon fill={fill} />,
              },
            ],
    },
  ];

  const MONITORDOG_NAV: NavType[] = [
    {
      name: t('nav_monitordog_logs'),
      icon: (fill: string) => <NavDashboard fill={fill} />,
      path: '/monitordog/logs',
      link: '/monitordog/logs',
    },
    ...SETTING_MONITORDOG,
  ];
  const RELIEF_NAV: NavType[] = [
    {
      name: t('nav_reliefmap_checks'),
      icon: (fill: string) => <NavDashboard fill={fill} />,
      path: '/reliefmap/checks',
      link: '/reliefmap/checks',
    },
    ...SETTING_NAV_RELIEF,
  ];
  const TOUCHECK_NAV: NavType[] = [
    {
      name: t('dashboard'),
      path: '/toucheck/dashboard',
      link: '/toucheck/dashboard',
      icon: (fill: string) => <NavDashboard fill={fill} />,
    },
    {
      name: t('nav_toucheck_checks'),
      icon: (fill: string) => <CheckNavIcon fill={fill} />,
      path: '/toucheck/checks',
      link: '/toucheck/checks',
      children: [
        {
          name: t('check_calendar'),
          path: '/toucheck/checks/calendar',
          link: '/toucheck/checks/calendar',
          icon: (fill: string) => <NavDotIcon fill={fill} />,
        },
        {
          name: t('toucheck_plan'),
          path: '/toucheck/checks/schedule',
          link: '/toucheck/checks/schedule',
          icon: (fill: string) => <NavDotIcon fill={fill} />,
        },
        {
          name: t('toucheck_checklist'),
          path: '/toucheck/checks/checklist',
          link: '/toucheck/checks/checklist',
          icon: (fill: string) => <NavDotIcon fill={fill} />,
        },
        {
          name: t('inspection_title'),
          path: '/toucheck/checks/logs',
          link: '/toucheck/checks/logs',
          icon: (fill: string) => <NavDotIcon fill={fill} />,
        },
      ],
    },

    {
      name: t('nav_toucheck_facility'),
      icon: (fill: string) => <NavInstall fill={fill} />,
      path: '/toucheck/facilities',
      link: '/toucheck/facilities',
    },
    {
      name: t('nav_toucheck_issues'),
      icon: (fill: string) => <CheckIssueIcon fill={fill} />,
      path: '/toucheck/issues',
      link: '/toucheck/issues',
    },
    {
      name: t('check_group_nav'),
      icon: (fill: string) => <CheckManagerIcon fill={fill} />,
      path: '/toucheck/group',
      link: '/toucheck/group',
      children: [
        {
          name: t('check_department'),
          icon: (fill: string) => <NavDotIcon fill={fill} />,
          path: '/toucheck/group/departments',
          link: '/toucheck/group/departments',
        },
        {
          name: t('check_member_name'),
          path: '/toucheck/group/members',
          link: '/toucheck/group/members',
          icon: (fill: string) => <NavDotIcon fill={fill} />,
        },
        {
          name: t('nav_toucheck_managers'),
          icon: (fill: string) => <NavDotIcon fill={fill} />,
          path: '/toucheck/group/workers',
          link: '/toucheck/group/workers',
        },
      ],
    },

    ...SETTING_TOUCHECK,
  ];

  const TOUCHSIREN_NAV: NavType[] = [
    {
      name: t('nav_touchsiren_dashboard'),
      icon: (fill: string) => <NavDashboard fill={fill} />,
      path: '/touchsiren/dashboard',
      link: '/touchsiren/dashboard',
    },
    {
      name: t('nav_touchsiren_report'),
      icon: (fill: string) => <NavReport fill={fill} />,
      path: '/touchsiren/report',
      link: '/touchsiren/report',
    },
    {
      name: t('nav_touchsiren_tags'),
      icon: (fill: string) => <NavInstall fill={fill} />,
      path: '/touchsiren/tags',
      link: '/touchsiren/tags',
    },
    {
      name: t('nav_touchsiren_checks'),
      icon: (fill: string) => <NavInspection fill={fill} />,
      path: '/touchsiren/checks',
      link: '/touchsiren/checks',
    },
    ...SETTING_NAV_TOUCHSIREN,
  ];

  const clickOutside = (event: Event) => {
    if (navStatusState)
      if (window.innerWidth <= 768) {
        if (ref?.current && !ref?.current?.contains(event.target as Node)) {
          setNavStatus(false);
        }
      }
  };
  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);

    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, []);
  return (
    <>
      <aside
        ref={ref}
        className={classNames(
          `relative flex flex-col z-[400] min-h-screen max-h-screen bg-white shadow-box py-5 shrink-0`,
          {
            'pl-5 pr-1 ': navStatus,
            'px-0 w-20': !navStatus,
          },
        )}
      >
        <div
          onClick={() => setNavStatus((cur) => !cur)}
          className='absolute p-2 bg-white top-6 -right-5 shadow-box rounded-10'
        >
          <div
            className={classNames('', {
              'rotate-90': navStatus,
              '-rotate-90': !navStatus,
            })}
          >
            <ArrowBottomIcon />
          </div>
        </div>

        <div
          className={classNames('flex items-center px-6 mb-8', {
            'justify-center': !navStatus,
          })}
        >
          <img
            src={LogoIcon}
            alt='로고'
            width='20'
            height='20'
            className='bg-transparent size-5'
          />
          {navStatus && (
            <img
              src={LogoText}
              alt='로고'
              width='154'
              height='10'
              className='scale-75'
            />
          )}
        </div>

        <nav
          className={classNames('flex flex-col h-full gap-10 overflow-y-auto', {
            'pr-4': navStatus,
          })}
        >
          {/* 터치 사이렌 NAV */}
          {touchsirenValue && (
            <NavItem title={t('touchsiren')} item={TOUCHSIREN_NAV} />
          )}

          {/* divide line only close nav */}
          {!navStatus && touchsirenValue && reliefValue && (
            <div className={`w-full h-px bg-theme_border`} />
          )}

          {/* 릴리의 지도 NAV */}
          {reliefValue && <NavItem title={t('reliefmap')} item={RELIEF_NAV} />}

          {/* 모니터독 NAV */}
          {monitordogValue && (
            <NavItem title={t('monitordog')} item={MONITORDOG_NAV} />
          )}
          {/* Toucheck Nav */}
          {toucheckValue && (
            <NavItem title={t('toucheck')} item={TOUCHECK_NAV} />
          )}
        </nav>
        {appName === 'toucheck' && (
          <>
            <a
              href='https://spresto.oopy.io/touchcheck/guide'
              target='_blank'
              className='flex items-center gap-5 px-6 py-5'
              rel='noreferrer'
            >
              <div>
                <ServiceGuideIcon />
              </div>
              {navStatus && (
                <span className='font-bold text-theme_check'>
                  {t('check_guide')}
                </span>
              )}
            </a>
          </>
        )}
      </aside>
    </>
  );
};

export default SideBar;
