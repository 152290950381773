import { useQuery } from '@tanstack/react-query';

import { getFacilityForIdForToucheck } from '@services/toucheck/apiClient_toucheck';

const useGetFacility = ({ uuid }: { uuid: string }) => {
  const fetchApi = async () => {
    const data = await getFacilityForIdForToucheck(uuid);
    return data;
  };
  return useQuery({
    queryKey: ['toucheck', 'facility', uuid],
    queryFn: fetchApi,
    enabled: Boolean(uuid),
  });
};

export default useGetFacility;
