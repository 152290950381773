import { Outlet } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import NotAllwoed from '@apps/NotAllwoed';

import { toucheckAccountState } from '@context/atom';

import { SwitchCase } from '@components/Utils/SwitchCase';

const AdminRoute = () => {
  const toucheckValue = useRecoilValue(toucheckAccountState);

  return (
    <SwitchCase
      value={toucheckValue?.grade ?? ''}
      caseBy={{
        admin: <Outlet />,
        general: <NotAllwoed />,
      }}
    />
  );
};

export default AdminRoute;
