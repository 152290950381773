import classNames from 'classnames';

type Props = {
  title: string;
  button?: React.ReactNode;
  trans?: React.ReactNode;
  theme?: string;
  children: React.ReactNode;
};

const DetailBoxLayout = ({
  title,
  trans,
  button,
  theme = 'bg-text_gray',
  children,
}: Props) => {
  return (
    <section className='shadow-basic rounded-20'>
      <div className={classNames('w-full h-5 rounded-t-20', theme)} />
      <div className='py-10 bg-white px-15 rounded-b-20'>
        <div className='flex items-center justify-between'>
          <h3 className='text-xl font-bold leading-7.5'>
            {title}
            {trans && (
              <span className='pl-5 text-xl leading-7.5 text-text_grayscale2'>
                {trans}
              </span>
            )}
          </h3>
          {button && <div>{button}</div>}
        </div>
        <div className='h-10' />
        <div>{children}</div>
      </div>
    </section>
  );
};

export default DetailBoxLayout;
