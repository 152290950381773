const ReportTime = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M15.4338 15.4487C15.9577 15.6233 16.524 15.3402 16.6987 14.8162C16.8733 14.2923 16.5902 13.726 16.0662 13.5513L15.4338 15.4487ZM12 13.25H11C11 13.6804 11.2754 14.0626 11.6838 14.1987L12 13.25ZM13 8.02319C13 7.4709 12.5523 7.02319 12 7.02319C11.4477 7.02319 11 7.4709 11 8.02319H13ZM16.0662 13.5513L12.3162 12.3013L11.6838 14.1987L15.4338 15.4487L16.0662 13.5513ZM13 13.25V8.02319H11V13.25H13ZM21 12C21 16.9706 16.9706 21 12 21V23C18.0751 23 23 18.0751 23 12H21ZM12 21C7.02944 21 3 16.9706 3 12H1C1 18.0751 5.92487 23 12 23V21ZM3 12C3 7.02944 7.02944 3 12 3V1C5.92487 1 1 5.92487 1 12H3ZM12 3C16.9706 3 21 7.02944 21 12H23C23 5.92487 18.0751 1 12 1V3Z'
        fill='#8A92A6'
      />
    </svg>
  );
};

export default ReportTime;
