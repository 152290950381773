import API from '@services/axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const useReadNotification = () => {
  const queryClient = useQueryClient();

  const fetchApi = async ({ ids }: { ids: number[] }) => {
    await API.put(`notifications/read`, {
      ids,
    });
  };
  return useMutation({
    mutationFn: fetchApi,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    },
  });
};

export default useReadNotification;
