import { lazy } from 'react';
import { Outlet, createBrowserRouter } from 'react-router-dom';

import Home from '@apps/index';
import FacilitiesDetailPage from '@apps/toucheck/facility/pages/details';
import BillingChangePage from '@apps/toucheck/settings/subscribe/page/change';
import BillingFailPage from '@apps/toucheck/settings/subscribe/page/fail';
import PaymentSuccessPage from '@apps/toucheck/settings/subscribe/page/success';

import RouteLayout from '@components/styles/layout/RouteLayout';
import UserLayout from '@components/styles/layout/UserLayout';

import AdminRoute from './AdminRoute';
import PlanRoute from './PlanRoute';
import ProtectedRoute from './ProtectedRoute';

/* ------------------------------- Monitor Dog ------------------------------ */
const MonitordogLogs = lazy(() => import('@apps/monitordog/logList'));
const MembersMonitordogPage = lazy(
  () => import('@apps/monitordog/settings/member'),
);
const MonitordogMypage = lazy(() => import('@apps/monitordog/settings/mypage'));

/* ------------------------------- Relief Map ------------------------------- */
const ReliefmapChecks = lazy(() => import('@apps/reliefmap/checks'));
const ReliefMembers = lazy(() => import('@apps/reliefmap/settings/members'));
const ReliefMypage = lazy(() => import('@apps/reliefmap/settings/mypage'));

/* ------------------------------- Touch Siren ------------------------------ */

const Dashboard = lazy(() => import('@apps/tochsiren/dashboard'));
const Inspections = lazy(() => import('@apps/tochsiren/inspections'));
const SituationInstallations = lazy(
  () => import('@apps/tochsiren/installation'),
);
const Reports = lazy(() => import('@apps/tochsiren/reports'));
const UserInvite = lazy(() => import('@apps/tochsiren/settings/member'));
const Mypage = lazy(() => import('@apps/tochsiren/settings/mypage'));
const ReportTypes = lazy(() => import('@apps/tochsiren/settings/report-types'));
const SettingsSpots = lazy(() => import('@apps/tochsiren/settings/spots'));

/* ------------------------------- Touch Check ------------------------------ */

const CheckCalendarPage = lazy(
  () => import('@apps/toucheck/checks/pages/calendar'),
);
const CheckHistoriesPage = lazy(
  () => import('@apps/toucheck/checks/pages/checkHistory'),
);
const CheckDetails = lazy(
  () => import('@apps/toucheck/checks/pages/checkHistory/details'),
);
const CheckChecklistPage = lazy(
  () => import('@apps/toucheck/checks/pages/checklist'),
);
const ChecklistDetail = lazy(
  () => import('@apps/toucheck/checks/pages/checklist/detail'),
);
const ToucheckEdit = lazy(
  () => import('@apps/toucheck/checks/pages/checklist/edit'),
);
const ToucheckEnroll = lazy(
  () => import('@apps/toucheck/checks/pages/checklist/enroll'),
);
const ToucheckChecks = lazy(() => import('@apps/toucheck/checks/pages/home'));
const CheckScheduleDetail = lazy(
  () => import('@apps/toucheck/checks/pages/schedule/page/detail'),
);
const CheckScheduleEnrollment = lazy(
  () => import('@apps/toucheck/checks/pages/schedule/page/enrollment'),
);
const CheckSchedulePage = lazy(
  () => import('@apps/toucheck/checks/pages/schedule/page/schedule'),
);
const CheckDashboardPage = lazy(() => import('@apps/toucheck/dashboard'));
const Facilities = lazy(() => import('@apps/toucheck/facility'));
const FacilitySticker = lazy(
  () => import('@apps/toucheck/facility/pages/facility_sticker'),
);
const GroupDepartmentPage = lazy(
  () => import('@apps/toucheck/group/department'),
);
const DepartmentDetails = lazy(
  () => import('@apps/toucheck/group/department/pages/details'),
);
const ToucheckWorkers = lazy(() => import('@apps/toucheck/group/managers'));
const ToucheckMembers = lazy(() => import('@apps/toucheck/group/members'));
const MemberDetailPage = lazy(
  () => import('@apps/toucheck/group/members/pages/details'),
);
const ToucheckIssues = lazy(() => import('@apps/toucheck/issues'));
const CoporationPage = lazy(() => import('@apps/toucheck/settings/coporation'));
const ToucheckMypage = lazy(() => import('@apps/toucheck/settings/mypage'));
const SubscribeSettings = lazy(
  () => import('@apps/toucheck/settings/subscribe'),
);
const SubscribePaymentPage = lazy(
  () => import('@apps/toucheck/settings/subscribe/page/payment'),
);
const SubscribePlanPage = lazy(
  () => import('@apps/toucheck/settings/subscribe/page/plan'),
);
const IntergratePage = lazy(() => import('@apps/user/intergrate'));
const JoinPage = lazy(() => import('@apps/user/join'));
const LoginPage = lazy(() => import('@apps/user/login'));
const Password = lazy(() => import('@apps/user/password'));
const InitRegisterPage = lazy(() => import('@apps/user/register'));

const commonRoutes = [
  {
    path: '/',
    element: (
      <RouteLayout>
        <ProtectedRoute />
      </RouteLayout>
    ),
    children: [
      {
        path: '',
        element: <Home />,
      },
    ],
  },
  {
    path: '/join',
    element: <JoinPage />,
  },

  {
    path: '/integrate',
    element: <IntergratePage />,
  },
  {
    path: '/user',
    element: (
      <UserLayout>
        <Outlet />
      </UserLayout>
    ),
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'password',
        element: <Password />,
      },
      {
        path: 'register',
        element: <InitRegisterPage />,
      },
    ],
  },
];
const touchsireRoutes = [
  {
    path: '/touchsiren',
    element: (
      <RouteLayout>
        <ProtectedRoute />
      </RouteLayout>
    ),
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />,
      },

      {
        path: 'tags',
        element: <SituationInstallations />,
      },
      {
        path: 'checks',
        element: <Inspections />,
        children: [{ path: ':id', element: <Inspections /> }],
      },
      {
        path: 'report',
        element: <Reports />,
        children: [{ path: ':id', element: <Reports /> }],
      },

      {
        path: 'settings',
        children: [
          { path: 'mypage', element: <Mypage /> },
          { path: 'report-types', element: <ReportTypes /> },
          { path: 'spots', element: <SettingsSpots /> },
          { path: 'members', element: <UserInvite /> },
        ],
      },
    ],
  },
];
const reliefmapRoutes = [
  {
    path: '/reliefmap',
    element: (
      <RouteLayout>
        <ProtectedRoute />
      </RouteLayout>
    ),
    children: [
      { path: 'checks', element: <ReliefmapChecks /> },
      {
        path: 'settings',
        children: [
          { path: 'mypage', element: <ReliefMypage /> },
          { path: 'members', element: <ReliefMembers /> },
        ],
      },
    ],
  },
];

const toucheckRoutes = [
  {
    path: '/toucheck',
    element: (
      <RouteLayout>
        <ProtectedRoute />
      </RouteLayout>
    ),
    children: [
      {
        path: 'checks',
        children: [
          {
            path: '',
            element: <PlanRoute />,
            children: [
              { path: '', element: <ToucheckChecks /> },
              { path: 'calendar', element: <CheckCalendarPage /> },
            ],
          },

          {
            path: 'logs',
            children: [
              {
                path: '',
                element: <PlanRoute />,
                children: [
                  { path: '', element: <CheckHistoriesPage /> },
                  { path: ':id', element: <CheckDetails /> },
                ],
              },
            ],
          },
          {
            path: 'schedule',
            children: [
              {
                path: '',
                element: <PlanRoute />,
                children: [
                  { path: '', element: <CheckSchedulePage /> },
                  { path: 'enrollment', element: <CheckScheduleEnrollment /> },
                  { path: 'detail/:id', element: <CheckScheduleDetail /> },
                ],
              },
            ],
          },

          {
            path: 'checklist',
            children: [
              {
                path: '',
                element: <PlanRoute />,
                children: [
                  { path: '', element: <CheckChecklistPage /> },
                  {
                    path: ':uuid',
                    element: <ChecklistDetail />,
                  },
                  {
                    path: 'edit',
                    children: [{ path: ':uuid', element: <ToucheckEdit /> }],
                  },
                  { path: 'enrollment', element: <ToucheckEnroll /> },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'group',
        children: [
          {
            path: '',
            element: <PlanRoute />,
            children: [
              {
                path: 'departments',
                children: [
                  { path: '', element: <GroupDepartmentPage /> },
                  { path: ':uuid', element: <DepartmentDetails /> },
                ],
              },
              { path: 'workers', element: <ToucheckWorkers /> },
              {
                path: 'members',
                children: [
                  { path: '', element: <ToucheckMembers /> },
                  { path: ':id', element: <MemberDetailPage /> },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'dashboard',
        children: [
          {
            path: '',
            element: <PlanRoute />,
            children: [{ path: '', element: <CheckDashboardPage /> }],
          },
        ],
      },
      {
        path: 'facilities',
        children: [
          {
            path: '',
            element: <PlanRoute />,
            children: [
              { path: '', element: <Facilities /> },
              { path: 'sticker', element: <FacilitySticker /> },
              { path: ':uuid', element: <FacilitiesDetailPage /> },
            ],
          },
        ],
      },
      {
        path: 'issues',
        children: [
          {
            path: '',
            element: <PlanRoute />,
            children: [
              {
                path: '',
                element: <ToucheckIssues />,
              },
            ],
          },
        ],
      },

      {
        path: 'settings',
        children: [
          { path: 'mypage', element: <ToucheckMypage /> },

          {
            path: 'coporation',
            element: <AdminRoute />,
            children: [
              {
                path: '',
                element: <CoporationPage />,
              },
            ],
          },
          {
            path: 'subscribe',
            children: [
              {
                path: '',
                element: <AdminRoute />,
                children: [
                  { path: '', element: <SubscribeSettings /> },
                  { path: 'fail', element: <BillingFailPage /> },
                  { path: 'success', element: <PaymentSuccessPage /> },
                  { path: 'change', element: <BillingChangePage /> },
                  { path: 'plan', element: <SubscribePlanPage /> },
                  { path: 'payment', element: <SubscribePaymentPage /> },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const monitordogRoutes = [
  {
    path: '/monitordog',
    element: (
      <RouteLayout>
        <ProtectedRoute />
      </RouteLayout>
    ),
    children: [
      {
        path: 'logs',
        element: <MonitordogLogs />,
      },
      {
        path: 'settings',
        children: [
          { path: 'mypage', element: <MonitordogMypage /> },
          { path: 'members', element: <MembersMonitordogPage /> },
        ],
      },
    ],
  },
];
export const baseRoutes = createBrowserRouter([
  ...commonRoutes,
  ...touchsireRoutes,
  ...reliefmapRoutes,
  ...monitordogRoutes,
  ...toucheckRoutes,
]);
