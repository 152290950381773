import { forwardRef } from 'react';

import classNames from 'classnames';

type Props = {
  title: string;
  icon?: React.ReactNode;
  content: React.ReactNode;
  popover?: React.ReactNode;
  isBorder?: boolean;
};

const DetailIconItem = forwardRef<HTMLDivElement, Props>(
  function DetailIconItem(
    { title, icon, content, popover, isBorder = true }: Props,
    ref,
  ) {
    return (
      <div
        className={classNames('flex items-center py-6  first:pt-0', {
          'border-b border-text_grayscale2/50': isBorder,
          'pb-0': !isBorder,
        })}
      >
        <div ref={ref} className='flex items-center gap-4'>
          {icon && <div>{icon}</div>}
          <span className='w-full leading-7.5 font-bold text-theme_check'>
            {title}
          </span>
        </div>
        <div className='flex items-center'>
          <div className='w-full text-sm leading-7.5'>{content}</div>
          {popover && (
            <>
              <div className='w-2.5' />
              {popover}
            </>
          )}
        </div>
      </div>
    );
  },
);

export default DetailIconItem;
