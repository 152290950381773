const NavDotIcon = ({ fill = '#8A92A6' }: { fill?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M12.0212 16C9.79823 16 8 14.2301 8 12.0071C8 9.78407 9.79823 8 12.0212 8C14.2442 8 16 9.78407 16 12.0071C16 14.2301 14.2442 16 12.0212 16Z'
        fill={fill}
      />
    </svg>
  );
};

export default NavDotIcon;
