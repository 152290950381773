const NavSettings = ({ fill }: { fill: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        opacity='0.4'
        d='M22.6875 14.7255C22.4627 14.3805 22.1432 14.0355 21.729 13.817C21.3977 13.656 21.1847 13.3915 20.9954 13.081C20.3919 12.092 20.7469 10.7925 21.7527 10.206C22.936 9.5505 23.3147 8.09 22.6284 6.9515L21.8355 5.5945C21.161 4.456 19.6819 4.0535 18.5104 4.7205C17.4691 5.2725 16.1319 4.9045 15.5284 3.927C15.3391 3.605 15.2326 3.26 15.2563 2.915C15.2918 2.4665 15.1498 2.041 14.9368 1.696C14.499 0.983 13.7061 0.5 12.8305 0.5H11.162C10.2982 0.523 9.50535 0.983 9.06752 1.696C8.84269 2.041 8.71252 2.4665 8.73619 2.915C8.75985 3.26 8.65336 3.605 8.46402 3.927C7.86053 4.9045 6.52338 5.2725 5.49389 4.7205C4.31057 4.0535 2.84326 4.456 2.15693 5.5945L1.36411 6.9515C0.689618 8.09 1.06828 9.5505 2.23977 10.206C3.24559 10.7925 3.60058 12.092 3.00892 13.081C2.80776 13.3915 2.59476 13.656 2.26343 13.817C1.8611 14.0355 1.50611 14.3805 1.31678 14.7255C0.87895 15.4385 0.902616 16.3355 1.34044 17.083L2.15693 18.463C2.59476 19.199 3.41125 19.659 4.26324 19.659C4.66557 19.659 5.1389 19.544 5.51756 19.314C5.81339 19.1185 6.16839 19.0495 6.55888 19.0495C7.73037 19.0495 8.71252 20.004 8.73619 21.1425C8.73619 22.465 9.82484 23.5 11.1975 23.5H12.8068C14.1676 23.5 15.2563 22.465 15.2563 21.1425C15.2918 20.004 16.2739 19.0495 17.4454 19.0495C17.8241 19.0495 18.1791 19.1185 18.4867 19.314C18.8654 19.544 19.3269 19.659 19.7411 19.659C20.5812 19.659 21.3977 19.199 21.8355 18.463L22.6639 17.083C23.0898 16.3125 23.1253 15.4385 22.6875 14.7255Z'
        fill={fill}
      />
      <path
        d='M12.0186 15.5C10.0735 15.5 8.5 13.9513 8.5 12.0062C8.5 10.0611 10.0735 8.5 12.0186 8.5C13.9637 8.5 15.5 10.0611 15.5 12.0062C15.5 13.9513 13.9637 15.5 12.0186 15.5Z'
        fill={fill}
      />
    </svg>
  );
};

export default NavSettings;
