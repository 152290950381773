import { useEffect, useMemo, useState } from 'react';

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';

import useCurPage from '@hooks/useCurPage';

import PageNation from '@components/list/PageNation';

interface SelectableTableProps<T> {
  datas: T[];
  total: number;
  columns: ColumnDef<T>[];
  emptyText: string;
  rowSelection?: Record<string, boolean>;
  isManualPage?: boolean;
  isEdit?: boolean;
  onPage?: (v: number) => void;
  setRowSelection?: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
}

const TableType2 = <T extends object>({
  datas,
  total,
  columns,
  emptyText,
  rowSelection,
  isEdit = false,
  isManualPage,
  onPage,
  setRowSelection,
}: SelectableTableProps<T>) => {
  const { curPage } = useCurPage();
  const [pageIndex, setPageIndex] = useState(curPage ? Number(curPage) - 1 : 0);
  const [pageSize, setPageSize] = useState(isEdit ? 100000 : 10);
  const [data, setData] = useState(() => datas);
  useEffect(() => {
    setData(datas);
  }, [datas]);
  const table = useReactTable({
    data: data,
    columns,
    state: {
      rowSelection,
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    rowCount: total,
    enableRowSelection: isEdit,
    manualPagination: isManualPage,
    autoResetPageIndex: false,
    onPaginationChange: (updater) => {
      const newState =
        updater instanceof Function
          ? updater({
              pageIndex,
              pageSize,
            })
          : updater;
      setPageIndex(newState.pageIndex);
      setPageSize(newState.pageSize);
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const columnCount = columns.length;

  // Memoize the colgroup based on the number of columns
  const colGroup = useMemo(() => {
    if (isEdit) {
      // Normal column width when isEdit is false (all columns are equal)
      const normalColWidth = 100 / columnCount;

      // First column gets half of its normal width
      const firstColWidth = normalColWidth / 2;

      // Calculate remaining width and distribute among remaining columns
      const remainingWidth = (100 - firstColWidth) / (columnCount - 1);

      return (
        <colgroup>
          <col style={{ width: `${firstColWidth}%` }} />
          {/* First column gets half of its original width */}
          {columns.slice(1).map((_, index) => (
            <col key={index} style={{ width: `${remainingWidth}%` }} />
          ))}
        </colgroup>
      );
    } else {
      // When isEdit is false, distribute the width equally among all columns
      const colWidth = 100 / columnCount;
      return (
        <colgroup>
          {columns.map((_, index) => (
            <col key={index} style={{ width: `${colWidth}%` }} />
          ))}
        </colgroup>
      );
    }
  }, [columns, columnCount, isEdit]);

  useEffect(() => {
    if (isEdit) {
      setPageSize(100000);
    } else {
      setPageSize(10);
    }
  }, [isEdit]);

  return (
    <div className='w-full'>
      <div className='relative'>
        <table className='min-w-full bg-white table-auto'>
          {colGroup}
          <thead className='border-b-2 border-text_gray'>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className='text-text_gray'>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className='pb-5 text-left first:pl-7.5 last:pr-7.5 last:text-right'
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        </table>

        {/* Scrollable tbody */}
        <div className='overflow-y-auto custom-scrollbar max-h-[700px]'>
          <table className='min-w-full bg-white table-auto'>
            {colGroup}
            <tbody>
              {table.getRowModel().rows.length > 0 ? (
                <>
                  {table.getRowModel().rows.map((row) => (
                    <tr
                      key={row.id}
                      className={classNames(
                        'w-full border-b border-text_grayscale2/50',
                        {
                          'last:border-none': total < 10,
                        },
                      )}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className='text-left first:pl-7.5 last:pr-7.5 last:text-right py-5'
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              ) : (
                <tr className='w-full'>
                  <td
                    colSpan={table.getAllColumns().length}
                    className='w-full !text-center text-text_gray pt-10'
                  >
                    {emptyText}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {!isEdit && total > 10 && (
        <div className='flex flex-col items-center'>
          <div className='h-15' />

          <PageNation
            page={table.getState().pagination.pageIndex + 1}
            isSave={false}
            setPage={(v) => {
              onPage && onPage(v);
              table.setPageIndex(v - 1);
            }}
            count={total}
            limit={10}
          />
        </div>
      )}
    </div>
  );
};

export default TableType2;
