const HeaderBell = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='36'
      height='37'
      viewBox='0 0 36 37'
      fill='none'
    >
      <path
        d='M29.6543 17.968C28.5585 16.6884 28.0606 15.5796 28.0606 13.6957V13.0552C28.0606 10.6003 27.4956 9.0186 26.2672 7.43689C24.3739 4.98049 21.1866 3.5 18.0663 3.5H17.9337C14.879 3.5 11.7916 4.91251 9.86549 7.2692C8.56999 8.88263 7.93939 10.5323 7.93939 13.0552V13.6957C7.93939 15.5796 7.47426 16.6884 6.34573 17.968C5.51536 18.9107 5.25 20.1223 5.25 21.4336C5.25 22.7464 5.68084 23.9897 6.5455 25.0003C7.67403 26.2119 9.26768 26.9854 10.8956 27.1198C13.2526 27.3887 15.6095 27.49 18.0007 27.49C20.3905 27.49 22.7474 27.3208 25.1059 27.1198C26.7323 26.9854 28.326 26.2119 29.4545 25.0003C30.3177 23.9897 30.75 22.7464 30.75 21.4336C30.75 20.1223 30.4846 18.9107 29.6543 17.968Z'
        fill='#CACFDB'
      />
      <path
        d='M21.0126 29.3425C20.2628 29.1824 15.6935 29.1824 14.9436 29.3425C14.3026 29.4906 13.6094 29.835 13.6094 30.5904C13.6466 31.311 14.0685 31.947 14.6529 32.3503L14.6514 32.3518C15.4073 32.941 16.2943 33.3157 17.2231 33.4501C17.718 33.5181 18.2219 33.5151 18.7347 33.4501C19.662 33.3157 20.549 32.941 21.3048 32.3518L21.3034 32.3503C21.8877 31.947 22.3096 31.311 22.3469 30.5904C22.3469 29.835 21.6537 29.4906 21.0126 29.3425Z'
        fill='#8A92A6'
      />
    </svg>
  );
};

export default HeaderBell;
