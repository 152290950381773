import createDevice from '@hooks/useNotificationDevice';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDt04YANhM5TMcevdGC2w6zhN3DST9PL_c',
  authDomain: 'touch-siren-410006.firebaseapp.com',
  projectId: 'touch-siren-410006',
  storageBucket: 'touch-siren-410006.appspot.com',
  messagingSenderId: '550167052794',
  appId: '1:550167052794:web:db6641a1a6ff663e16a443',
  measurementId: 'G-8JMTCR6PVE',
};

const app = initializeApp(firebaseConfig, 'touchsiren-push');

export const messaging = getMessaging(app);

export const requestPermission = () => {
  void Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      getToken(messaging, {
        vapidKey: import.meta.env.VITE_BASSE_FIREBASE_MESSAGE_VAPID_KEY,
      })
        .then(async (currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            createDevice.createDevice({ token: currentToken });
          } else {
            // Show permission request UI
            console.log(
              'No registration token available. Request permission to generate one.',
            );
            // ...
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // ...
        });

      //
    } else if (permission === 'denied') {
      console.log('푸시 권한 차단');
    }
  });
};
