import { Link, useLocation } from 'react-router-dom';

import { Disclosure, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  appBgColorState,
  appColorState,
  appTextColorState,
  navStatusState,
} from '@context/atom';

import ArrowBottomIcon from '@assets/ArrowBottomIcon';

type NavType = {
  name: string;
  icon: (fill: string) => JSX.Element;
  path: string;
  link: string;
  children?: NavType[];
};

type Props = {
  item: NavType[];
  title: string;
};

const NavItem = ({ item, title }: Props) => {
  const { pathname } = useLocation();
  const [navStatus, setNavStatus] = useRecoilState(navStatusState);
  const appColor = useRecoilValue(appColorState);
  const appTextColor = useRecoilValue(appTextColorState);
  const appBgColor = useRecoilValue(appBgColorState);

  const isToggle = (path: string) => {
    const splitedPath = path
      .split('/')
      .filter((item) => item)
      .join('/');

    const splitedPathname = pathname.split('/').filter((item) => item);

    return splitedPath === [splitedPathname[0], splitedPathname[1]].join('/');
  };

  const checkURLContainsSubString = (url: string, subString: string) => {
    return url.includes(subString);
  };

  /* -------------------------------- Animation ------------------------------- */

  return (
    <div>
      {navStatus && (
        <div className='px-6 pb-4 font-bold text-text_gray'>{title}</div>
      )}
      <ul
        className={classNames('flex flex-col gap-5', {
          ' max-w-60': navStatus,
        })}
      >
        {item.map(({ name, link, path, children, icon }) =>
          children ? (
            <Disclosure key={name}>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    as='li'
                    onClick={() => setNavStatus(true)}
                    className={classNames(
                      `flex w-full items-center capitalize text-sm
                       gap-2 px-6 py-2 rounded-10 cursor-pointer select-none`,
                      {
                        'justify-between': navStatus,
                        'justify-center': !navStatus,
                        [appBgColor]: isToggle(path) && open && navStatus,
                      },
                    )}
                  >
                    <div className='flex items-center gap-5'>
                      <div className='scale-90'>
                        {navStatus
                          ? icon(isToggle(path) && open ? 'white' : '#8A92A6')
                          : icon(isToggle(path) ? appColor : '#8A92A6')}
                      </div>
                      {navStatus && (
                        <span
                          className={`font-bold ${
                            isToggle(path) && open
                              ? 'text-white'
                              : 'text-text_gray'
                          }`}
                        >
                          {name}
                        </span>
                      )}
                    </div>

                    {navStatus && (
                      <div
                        className={classNames('scale-90', {
                          'rotate-180': open,
                          'rotate-0': !open,
                        })}
                      >
                        <ArrowBottomIcon
                          stroke={isToggle(path) && open ? 'white' : '#8A92A6'}
                        />
                      </div>
                    )}
                  </Disclosure.Button>

                  {navStatus && (
                    <>
                      {children.map((item) => (
                        <Transition show={open} key={item.name}>
                          <Disclosure.Panel
                            className={`flex items-center w-full gap-2 py-2 px-3 rounded-10`}
                            static
                          >
                            <Link
                              className='flex items-center w-full gap-5 pl-3 capitalize'
                              to={item.link}
                            >
                              <div className='scale-90'>
                                {item.icon(
                                  checkURLContainsSubString(pathname, item.path)
                                    ? appColor
                                    : '#8A92A6',
                                )}
                              </div>
                              <span
                                className={`font-bold text-sm ${
                                  checkURLContainsSubString(pathname, item.path)
                                    ? appTextColor
                                    : 'text-text_gray'
                                }`}
                              >
                                {item.name}
                              </span>
                            </Link>
                          </Disclosure.Panel>
                        </Transition>
                      ))}
                    </>
                  )}
                </>
              )}
            </Disclosure>
          ) : (
            <li
              key={name}
              className={classNames(
                'py-2 px-6 rounded-10 select-none text-sm',
                {
                  [appBgColor]: path === pathname && navStatus,
                },
              )}
            >
              <Link
                to={link}
                className={classNames('flex items-center gap-5 capitalize', {
                  'justify-center': !navStatus,
                })}
              >
                <div className='scale-90'>
                  {navStatus
                    ? icon(path === pathname ? 'white' : '#8A92A6')
                    : icon(isToggle(path) ? appColor : '#8A92A6')}
                </div>
                {navStatus && (
                  <span
                    className={`font-bold ${
                      path === pathname ? 'text-white' : 'text-text_gray'
                    } `}
                  >
                    {name}
                  </span>
                )}
              </Link>
            </li>
          ),
        )}
      </ul>
    </div>
  );
};

export default NavItem;
