import { BasicModalType } from '@models/modal';

import BasicDialog from './BasicDialog';

type Props = BasicModalType & {
  title: string;
  content: React.ReactNode;
  confirmText?: string;
  closeText?: string;
  theme?: string;
  onConfirm?: () => void;
};

const SimpleDialog = ({
  isOpen,
  onConfirm,
  onClose,
  theme = 'bg-theme_basic',
  content,
  title,
  confirmText,
  closeText,
}: Props) => {
  return (
    <BasicDialog
      mainColor={theme}
      isOpen={isOpen}
      title={title}
      closeText={closeText}
      confirmText={confirmText}
      onConfirm={onConfirm}
      onClose={onClose}
    >
      <div className='text-lg text-center whitespace-pre-line break-keep'>
        {content}
      </div>
    </BasicDialog>
  );
};

export default SimpleDialog;
