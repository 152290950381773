const GroupEditIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='18'
      viewBox='0 0 15 18'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.41904 0.677084C10.3218 -0.225695 11.7855 -0.225695 12.6883 0.677084L14.3229 2.31171C15.2257 3.21449 15.2257 4.67818 14.3229 5.58096L12.8736 7.03028C12.7598 6.97394 12.6401 6.91302 12.516 6.84767C11.6806 6.40786 10.6892 5.79057 9.94934 5.05069C9.20947 4.31082 8.59218 3.31945 8.15237 2.48403C8.08701 2.35989 8.02608 2.24018 7.96974 2.12639L9.41904 0.677084ZM8.88868 6.11135C9.7642 6.98687 10.8777 7.67594 11.7595 8.14441L6.06004 13.8438C5.70635 14.1975 5.2475 14.4269 4.75234 14.4977L1.31963 14.9881C0.556801 15.097 -0.0970492 14.4432 0.0119258 13.6804L0.502313 10.2477C0.57305 9.7525 0.802479 9.29364 1.15616 8.93996L6.8556 3.24053C7.32407 4.12234 8.01315 5.23582 8.88868 6.11135ZM5.5 16.5C5.08579 16.5 4.75 16.8358 4.75 17.25C4.75 17.6642 5.08579 18 5.5 18H14.25C14.6642 18 15 17.6642 15 17.25C15 16.8358 14.6642 16.5 14.25 16.5H5.5Z'
        fill='#8A92A6'
      />
    </svg>
  );
};

export default GroupEditIcon;
