import {
  ScheduleDomainValuesCycleType,
  ScheduleDomainValuesDayCountType,
  ScheduleDomainValuesMonthOnlyType,
} from '@/model/touchcheck';
import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';

import { CheckListQusttionType } from '@models/toucheck/checklist_enroll';

export const createDefaultQuestion = (): CheckListQusttionType => {
  return {
    id: uuidv4(),
    type: 'check_list',
    question: '',
    description: '',
    sub_type: 'check',
    is_required: true,
    is_photo_required: false,
  };
};
export const createDefaultSection = (): CheckListQusttionType => {
  return {
    id: uuidv4(),
    type: 'section',
    question: '',
    description: '',
    sub_type: 'none',
    is_required: true,
    is_photo_required: false,
  };
};

export const setCycleType = (cycle_type: string) => {
  switch (cycle_type) {
    case ScheduleDomainValuesCycleType.Daily:
      return 'per_day_count';
    case ScheduleDomainValuesCycleType.Weekly:
      return 'per_week_count';
    case ScheduleDomainValuesCycleType.Monthly:
      return 'per_month_count';
    default:
      return 'per_anytime';
  }
};

export const setTime = (check_times: string[]) => {
  return `${check_times.map((item) => `(${item})`).join(', ')}`;
};

export const setMonth = (
  cycle_type: string,
  month_only_type: string,
  month_weeks?: string[],
) => {
  if (cycle_type !== ScheduleDomainValuesCycleType.Monthly) return [];
  if (month_only_type === ScheduleDomainValuesMonthOnlyType.DayOfWeek)
    return month_weeks?.map((item) => {
      const [week, day] = item.split('/');
      return { week, day };
    });
  return [];
};

export const setDays = (
  cycle_type: string,

  week_days?: string[],
) => {
  switch (cycle_type) {
    case ScheduleDomainValuesCycleType.Weekly:
      return week_days;

    default:
      return [];
  }
};
export const setDate = (cycle_type: string, month_days?: number[]) => {
  switch (cycle_type) {
    case ScheduleDomainValuesCycleType.Monthly:
      return month_days;

    default:
      return [];
  }
};

export const setCount = ({
  day_count_type,
  check_times,
  reserved_check_count,
}: {
  day_count_type: string;
  check_times: string[];
  reserved_check_count: number;
}) => {
  switch (day_count_type) {
    case ScheduleDomainValuesDayCountType.Count:
      return reserved_check_count;
    case ScheduleDomainValuesDayCountType.Time:
      return check_times.length;
    default:
      return 0;
  }
};

export const setDailySummary = (
  day_count_type: string,
  check_times: string[],
  count: string,
) => {
  switch (day_count_type) {
    case ScheduleDomainValuesDayCountType.Count:
      return `| ${count}`;
    case ScheduleDomainValuesDayCountType.Time:
      return `| ${count} ${setTime(check_times)}`;
    default:
      return 0;
  }
};

export const setEliminateTime = (
  count: number,
  time: {
    hours: number;
    minutes: number;
    seconds: number;
  },
) => {
  const { hours, minutes, seconds } = time;

  return `${t('check_result_download_content', { count })}
  ${t('check_result_download_estimated')} ${hours > 0 ? `${t('check_result_download_time_hours', { hours })} ` : ''}${minutes > 0 ? `${t('check_result_download_time_minutes', { minutes })} ` : ''}${seconds > 0 ? `${t('check_result_download_time_seconds', { seconds })} ` : ''}
  ${t('check_result_download_ok')}
  `;
};

export const formatTime = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return {
    hours,
    minutes,
    seconds,
  };
};

export const formatPhoneNumber = (value: string) => {
  if (!value) return value;

  // 숫자만 남기기
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;

  // 포맷팅 로직
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  }
  if (phoneNumberLength < 11) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
  }
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}-${phoneNumber.slice(7, 11)}`;
};
