import { NotificationDevice } from '@models/notification/notification_device';
import API from '@services/axios';
import Cookies from 'js-cookie';

const fetchApi = async ({ token }: { token: string }): Promise<void> => {
  const { data }: { data: NotificationDevice } = await API.post(
    'notification/device',
    {
      token,
    },
  );
  Cookies.set('firebase_message_token', data.token);
};
export default { createDevice: fetchApi };
