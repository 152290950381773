import Cookies from 'js-cookie';

import { NfcTag } from '@models/nfc_tag/nfc_tag';

export const getLatLng = async ({ address }: { address?: string }) => {
  if (!address) return;
  const res = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${import.meta.env.VITE_GOOGLE_MAP_KEY}`,
  );
  const data = await res.json();
  return data;
};

export const generatorGoogleMap = ({
  size,
  lat,
  lng,
}: {
  size: string;
  lat: string;
  lng: string;
}) => {
  return `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&language=en&cale=2&size=${size}&zoom=16&markers=size:mid|color:0xF6C000|${lat},${lng}&key=${import.meta.env.VITE_GOOGLE_MAP_KEY}`;
};

export const generatorNaverMap = ({
  lat,
  lng,
}: {
  lat: string;
  lng: string;
}) => {
  return `https://naveropenapi.apigw.ntruss.com/map-static/v2/raster-cors?scale=2&w=930&h=300&center=${lng},${lat}&markers=type:d|size:mid|color:0xF6C000|pos:${lng}%20${lat}&level=14&X-NCP-APIGW-API-KEY-ID=2fves73v77`;
};

export const generateMap = ({
  size,
  lat,
  lng,
}: {
  size: string;
  lat: string;
  lng: string;
}) => {
  const lang = Cookies.get('i18next');

  switch (lang) {
    case 'ko':
      return generatorNaverMap({ lat, lng });
    default:
      return generatorGoogleMap({ size, lat, lng });
  }
};

type GroupByStatus = {
  [key: string]: NfcTag[];
};
export const decideColorByStatus = (tags: NfcTag[]) => {
  const groupbyStatus = tags.reduce((acc: GroupByStatus, obj: NfcTag) => {
    if (!acc[obj.status]) {
      acc[obj.status] = [];
    }
    acc[obj.status].push(obj);
    return acc;
  }, {});
  if (groupbyStatus['broken']) return 'broken';
  if (groupbyStatus['processing']) return 'processing';
  return 'active';
};

export const transStatusToColor = (status: string) => {
  switch (status) {
    case 'active':
      return '#05CD99';

    case 'processing':
      return '#F6C000';
    case 'broken':
      return '#FF4273';
    case 'deactivated':
      return '#7A7A7A';
    default:
      return '#7A7A7A';
  }
};
