import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Schedule } from '@/model/touchcheck';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';

import useGetScheduleList from '@apps/toucheck/checks/pages/schedule/hooks/useGetScheduleList';
import TableType2 from '@apps/toucheck/group/department/pages/details/components/TableType2';

import { getScheduleForId } from '@services/toucheck/apiClient_toucheck';

import { transformDotDateFormat } from '@utils/date';
import { delayFn } from '@utils/index';
import { setCycleType } from '@utils/toucheck';

import DetailBoxLayout from '@components/details/DetailBoxLayout';
import ListShowButton from '@components/list/ListShowButton';
import Loader from '@components/loader/Loader';

type Props = {
  uuid: string;
};

const FacilitySchedule = ({ uuid }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false);

  const { data } = useGetScheduleList({
    page: 1,
    per_page: 10000,
    f_facility_uuids: [uuid],
    count: 0,
  });

  const onClick = async (id: string) => {
    setIsLoading(true);
    await queryClient.prefetchQuery({
      queryKey: ['toucheck', 'schedule', id],
      queryFn: async () => {
        const data = await getScheduleForId({ uuid: id });
        return data;
      },
      gcTime: 60 * 1000,
    });

    await delayFn(200);

    navigate(`/toucheck/checks/schedule/detail/${id}`);
  };

  const columns = useMemo<ColumnDef<Schedule>[]>(
    () => [
      {
        accessorKey: 'schedule_version.name',
        header: t('toucheck_list_title'),
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'schedule_version',
        header: t('toucheck_list_frequency'),
        cell: ({ row }) => {
          const schedule = row.original.schedule_version;
          return t(setCycleType(schedule?.cycle_type ?? ''), {
            count: schedule?.cycle_unit ?? 0,
          });
        },
      },
      {
        accessorKey: 'schedule_version.start_date',
        header: t('toucheck_list_date'),
        cell: (info) => transformDotDateFormat(info.getValue() as string),
      },
      {
        accessorKey: 'schedule_version.workers',
        header: t('toucheck_list_manager'),
        cell: ({ row }) => {
          const workers = row.original.schedule_version?.workers;
          return (workers ?? []).length > 0
            ? workers?.map((item) => item.name).join(', ')
            : t('check_manager_empty');
        },
      },
      {
        accessorKey: 'uuid',
        header: t('list_detail'),
        cell: (info) => (
          <ListShowButton
            showDetails={() => onClick(info.getValue() as string)}
          />
        ),
      },
    ],
    [],
  );

  return (
    <>
      <DetailBoxLayout
        title={t('schdules')}
        theme='bg-theme_check'
        trans={
          <Trans
            t={t}
            i18nKey='check_facility_count_trans'
            values={{ count: data?.total ?? 0 }}
            components={{
              1: <mark className='bg-transparent text-theme_check' />,
            }}
          />
        }
      >
        <TableType2
          datas={data?.schedules ?? []}
          total={data?.total ?? 0}
          columns={columns}
          emptyText={t('empty_schedules')}
        />
      </DetailBoxLayout>
      <Loader isOpen={isLoading} />
    </>
  );
};

export default FacilitySchedule;
