const ErrorIcon = () => {
  return (
    <div className='w-[34px] h-[34px] bg-text_red/50 rounded-full flex items-center justify-center'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <mask
          id='mask0_197_664'
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='24'
          height='24'
        >
          <rect width='24' height='24' fill='#D9D9D9' />
        </mask>
        <g mask='url(#mask0_197_664)'>
          <path
            d='M6.84 17.16C7.30392 17.6239 8.05608 17.6239 8.52 17.16L12 13.68L15.48 17.16C15.9439 17.6239 16.6961 17.6239 17.16 17.16C17.6239 16.6961 17.6239 15.9439 17.16 15.48L13.68 12L17.16 8.52C17.6239 8.05608 17.6239 7.30392 17.16 6.84C16.6961 6.37608 15.9439 6.37608 15.48 6.84L12 10.32L8.52 6.84C8.05608 6.37608 7.30392 6.37608 6.84 6.84C6.37608 7.30392 6.37608 8.05608 6.84 8.52L10.32 12L6.84 15.48C6.37608 15.9439 6.37608 16.6961 6.84 17.16ZM12 24C10.34 24 8.78 23.6848 7.32 23.0544C5.86 22.4248 4.59 21.57 3.51 20.49C2.43 19.41 1.5752 18.14 0.9456 16.68C0.3152 15.22 0 13.66 0 12C0 10.34 0.3152 8.78 0.9456 7.32C1.5752 5.86 2.43 4.59 3.51 3.51C4.59 2.43 5.86 1.5748 7.32 0.9444C8.78 0.3148 10.34 0 12 0C13.66 0 15.22 0.3148 16.68 0.9444C18.14 1.5748 19.41 2.43 20.49 3.51C21.57 4.59 22.4248 5.86 23.0544 7.32C23.6848 8.78 24 10.34 24 12C24 13.66 23.6848 15.22 23.0544 16.68C22.4248 18.14 21.57 19.41 20.49 20.49C19.41 21.57 18.14 22.4248 16.68 23.0544C15.22 23.6848 13.66 24 12 24Z'
            fill='#FF4273'
          />
        </g>
      </svg>
    </div>
  );
};

export default ErrorIcon;
