import { useTranslation } from 'react-i18next';

import { UpdateFacilityReq } from '@/model/touchcheck';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateFacilityForIdForToucheck } from '@services/toucheck/apiClient_toucheck';

import useToast from '@hooks/useToast';

const useUpdateFacility = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { notify } = useToast();
  const fetchApi = async ({
    body,
    uuid,
  }: {
    body: UpdateFacilityReq;
    uuid: string;
  }) => {
    const data = await updateFacilityForIdForToucheck({ body, uuid });
    return data;
  };
  return useMutation({
    mutationFn: fetchApi,
    onSuccess: (data) => {
      notify({
        type: 'success',
        title: t('integrate_success'),
        content: t('facility_update_success'),
      });
      queryClient.invalidateQueries({ queryKey: ['toucheck', 'facilities'] });
      queryClient.invalidateQueries({
        queryKey: ['toucheck', 'facility', data.uuid],
      });
    },
    onError: () => {
      notify({
        type: 'error',
        title: t('integrate_fail'),
        content: t('retry'),
      });
    },
  });
};

export default useUpdateFacility;
