import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import '@/i18n';
import * as ChannelService from '@channel.io/channel-web-sdk-loader';
import * as Sentry from '@sentry/react';

import ClientRoot from '@context/ClientRoot.tsx';

import App from './App.tsx';
import './index.css';

Sentry.init({
  dsn: 'https://bcd367034e854be8c85f55cef5ba9514@o348151.ingest.us.sentry.io/4508125512597504',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ChannelService.loadScript();

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ClientRoot>
      <App />
    </ClientRoot>
  </StrictMode>,
);
