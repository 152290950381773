import { toast } from 'react-toastify';

import ToastStatus from '@components/popup/toast/ToastStatus';

import ErrorIcon from '@assets/ErrorIcon';
import ProblemIcon from '@assets/ProblemIcon';
import SuccessIcon from '@assets/SuccessIcon';

type Params = {
  type: 'success' | 'problem' | 'error';
  title: string;
  content: string;
};
const useToast = () => {
  const notify = ({ type, title, content }: Params) => {
    switch (type) {
      case 'error':
        toast.error(<ToastStatus title={title} content={content} />, {
          icon: <ErrorIcon />,
        });
        break;

      case 'problem':
        toast.warn(<ToastStatus title={title} content={content} />, {
          icon: <ProblemIcon />,
        });
        break;
      case 'success':
        toast.success(<ToastStatus title={title} content={content} />, {
          icon: <SuccessIcon />,
        });
        break;
    }
  };
  return { notify };
};

export default useToast;
