import dayjs from 'dayjs';

export const transformSlashDateFormat = (date: string) => {
  const day = dayjs(date);
  return day.format('YY/MM/DD');
};
export const transformDotDateFormat = (date: string | Date) => {
  if (date) {
    const day = dayjs(date);
    return day.format('YY.MM.DD');
  }
  return '';
};
export const transformFromDateFormat = (date?: Date | null) => {
  if (date) {
    const day = dayjs(date);
    return day.format('YYYY-MM-DD 00:00:00');
  }
  return '';
};
export const transformFromDateBeforeDaysFormat = (date: Date | null) => {
  if (date) {
    const day = dayjs(date);
    return day.subtract(7, 'day').format('YYYY-MM-DD 00:00:00');
  }
  return '';
};

export const subOneMonth = (date: Date | null) => {
  if (date) {
    const day = dayjs(date);
    return new Date(day.subtract(1, 'month').format('YYYY-MM-DD 00:00:00'));
  }
  return new Date();
};

export const subInfiite = (date: Date | null) => {
  if (date) {
    const day = dayjs(date);
    return new Date(day.subtract(10, 'year').format('YYYY-MM-DD 00:00:00'));
  }
  return new Date();
};

export const transformToDateFormat = (date?: Date | null) => {
  if (date) {
    const day = dayjs(date);
    return day.format('YYYY-MM-DD 23:59:59');
  }
  return '';
};

export const transformSlashTimeDateFormat = (date: string) => {
  const day = dayjs(date);
  return day.format('MM/DD HH:mm:ss');
};

export const transformDotTimeDateFormat = (date: string) => {
  const day = dayjs(date);
  return day.format('YY.MM.DD HH:mm');
};
export const transformDotTime = (date?: string) => {
  if (!date) return '';
  const day = dayjs(date);
  return day.format('YY.MM.DD HH:mm:ss');
};

export const tranformMMDD = (date: string) => {
  const day = dayjs(date);
  return day.format('MM.DD');
};

export const tranformMD = (date: string) => {
  const day = dayjs(date);
  return day.format('M/D');
};

export const tranformMMDDTIme = (date: string) => {
  const day = dayjs(date);
  return day.format('MM/DD HH:mm:ss');
};

export const transformFullDate = (date?: string) => {
  if (!date) return '';

  const day = dayjs(date);
  return day.format('YYYY.MM.DD (ddd)');
};

export const transformFullDateTime = (date?: string) => {
  if (!date) return '';

  const day = dayjs(date);
  return day.format('YYYY.MM.DD (ddd) HH:mm:ss');
};

export const getTime = (date?: string) => {
  if (!date) return '';
  return dayjs(date).format('HH:mm');
};

export const isTimeDifferenceGreaterThan = (
  time1: string,
  time2: string,
  hour: number,
) => {
  const date1 = dayjs(time1);
  const date2 = dayjs(time2);

  const timeDif = Math.abs(date2.diff(date1, 'minute'));

  return timeDif > hour * 60;
};

export const isDateBefore = (date: string) => {
  const day = dayjs(date);
  const startOfToday = dayjs().startOf('day');

  // 입력받은 날짜가 오늘의 끝나는 시간 이후인지 확인하고, 같은 날인지 확인
  return day.isBefore(startOfToday);
};

export const caleandarDateTime = (date: Date, locale: string) => {
  if (locale === 'ko') {
    return new Intl.DateTimeFormat('ko-KR', {
      year: 'numeric',
      month: 'long',
    }).format(date);
  } else {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
    }).format(date);
  }
};

export const calFirstLastDayOnMonth = (date: Date) => {
  const parsedDate = dayjs(date);
  const formatDate = (date: dayjs.Dayjs) => date.format('YYYY-MM-DD');
  const startOfMonth = parsedDate.startOf('month');
  const endOfMonth = parsedDate.endOf('month');
  return {
    from_date: formatDate(startOfMonth),
    to_date: formatDate(endOfMonth),
  };
};

export const createDateKey = (date: Date) => {
  return dayjs(date).format('YYYY-MM-DD');
};

const getWeekNumber = (date: Date): number => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;

  // Calculate the week number
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

export const isLastWeekOfMonth = (
  dates: Date[],
  displayMonth: Date,
  weekNumber: number,
): boolean => {
  if (!dates || dates.length === 0) return false;

  const currentMonth = displayMonth.getMonth();
  const currentYear = displayMonth.getFullYear();

  // Calculate the last day of the current month
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);

  // Calculate the week number for the last day of the month
  const lastWeekNumberOfMonth = getWeekNumber(lastDayOfMonth);

  // Calculate the current week number

  return weekNumber === lastWeekNumberOfMonth;
};

export const isCheckCurrentMonth = (
  date: Date,
  displayMonth: Date,
): boolean => {
  return (
    date.getFullYear() === displayMonth.getFullYear() &&
    date.getMonth() === displayMonth.getMonth()
  );
};

export const isCheckToday = (date: Date) => {
  const today = new Date();
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
};

export const isAfterToday = (date: Date) => {
  const today = dayjs().endOf('day');
  const inputDate = dayjs(date).startOf('day');
  return inputDate.isAfter(today);
};
