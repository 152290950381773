export const transformColor = (
  color: string,
  type: 'text' | 'bg' | 'border' | 'hover',
) => {
  if (type === 'text')
    switch (color) {
      case '#FF4273':
        return 'text-text_red';
      case '#05CD99':
        return 'text-text_green';
      case '#0062FF':
        return 'text-theme_basic';
      case '#7A7A7A':
        return 'text-text_grayscale';
      case '#F6C000':
        return 'text-text_yellow';
    }
  if (type === 'hover')
    switch (color) {
      case '#FF4273':
        return 'hover:text-text_red';
      case '#05CD99':
        return 'hover:text-text_green';
      case '#0062FF':
        return 'hover:text-theme_basic';
      case '#7A7A7A':
        return 'hover:text-text_grayscale';
      case '#F6C000':
        return 'hover:text-text_yellow';
    }
  if (type === 'bg')
    switch (color) {
      case '#FF4273':
        return 'bg-text_red';
      case '#05CD99':
        return 'bg-text_green';
      case '#0062FF':
        return 'bg-theme_basic';
      case '#7A7A7A':
        return 'bg-text_grayscale';
      case '#F6C000':
        return 'bg-text_yellow';
    }
  if (type === 'border')
    switch (color) {
      case '#FF4273':
        return 'border-t-text_red';
      case '#05CD99':
        return 'border-t-text_green';
      case '#0062FF':
        return 'border-t-theme_basic';
      case '#7A7A7A':
        return 'border-t-text_grayscale';
      case '#F6C000':
        return 'border-t-text_yellow';
    }
};

export const makeHtmlForMarker = ({
  landmark,
  isClick = false,
  color,
}: {
  landmark: string;
  isClick?: boolean;
  color: string;
}) => {
  //

  const bgColor = transformColor(color, 'bg');
  const borderColor = transformColor(color, 'border');

  return `<div class="relative inline-flex items-center justify-start w-full gap-2 py-2 pl-2 pr-4 ${isClick ? bgColor : 'bg-white'} h-11 rounded-3xl shadow-box">
  <div class="w-7 h-7 p-2.5 ${isClick ? 'bg-white' : bgColor} rounded-3xl justify-center items-center gap-2.5 flex">
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill=${isClick ? color : 'white'}>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.53162 1.43677C5.71648 0.167274 8.40203 0.189463 10.5664 1.49489C12.7095 2.82691 14.012 5.20418 13.9998 7.76144C13.95 10.3019 12.5533 12.69 10.8075 14.5361C9.79983 15.6064 8.67261 16.5528 7.44884 17.356C7.32281 17.4289 7.18475 17.4777 7.04148 17.5C6.9036 17.4941 6.76932 17.4534 6.65075 17.3814C4.78243 16.1746 3.14334 14.634 1.81233 12.834C0.698589 11.3314 0.0658357 9.51601 1.16173e-06 7.63442C-0.00144468 5.07225 1.34677 2.70627 3.53162 1.43677ZM4.79416 8.69478C5.16168 9.60084 6.02918 10.1918 6.9916 10.1918C7.6221 10.1964 8.22819 9.94383 8.67481 9.49048C9.12143 9.03712 9.37148 8.42064 9.36925 7.77838C9.37261 6.79804 8.79546 5.91231 7.90726 5.53477C7.01907 5.15723 5.99504 5.36235 5.31332 6.05435C4.63159 6.74635 4.42664 7.78872 4.79416 8.69478Z" fill=${isClick ? color : 'white'}}/>
</svg>
  </div>
  <div class="text-center ${isClick ? 'text-white' : 'text-black'} text-sm font-bold leading-tight">${landmark}</div>
  <div class='w-0 h-0 border-[18px] border-transparent ${isClick ? borderColor : 'border-t-white'} rounded-xl absolute -bottom-7 left-5'></div>
</div>`;
};

export const statusToTextColorForReport = (status: string) => {
  switch (status) {
    case 'received':
      return 'text-text_red';
    case 'verifying':
      return 'text-text_yellow';
    case 'processing':
      return 'text-theme_basic';
    case 'canceled':
      return 'text-text';
    case 'completed':
      return 'text-text_green';
    default:
      return 'text-text_gray';
  }
};

export const statusToTextColorForTag = (status: string) => {
  switch (status) {
    case 'broken':
      return 'text-text_red';
    case 'processing':
      return 'text-text_yellow';
    case 'deactivate':
      return 'text-text_gray';
    case 'active':
      return 'text-text_green';
    default:
      return 'text-text_gray';
  }
};

export const getURLSearchParams = (url: string, id: string) => {
  const params = new URLSearchParams(url);
  const value = params.get(id);
  return value;
};

export const numberWithCommas = (x: string | number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const delayFn = async (delay?: number) => {
  await new Promise<void>((resolve) =>
    setTimeout(() => resolve(), delay ?? 300),
  );
};

export const getKoreanSuffix = (word: string) => {
  const lastChar = word[word.length - 1];
  const lastCharCode = lastChar.charCodeAt(0);

  if ((lastCharCode - 0xac00) % 28 === 0) {
    return '를';
  } else {
    return '을';
  }
};

export const setListColor = (dataLength: number, index: number) => {
  return dataLength % 2 === index % 2
    ? 'bg-theme_white_light'
    : 'bg-transparent';
};
