import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import BackBigIcon from '@assets/BackBigIcon';

type Props = {
  title: string;
  onClick?: () => void;
  onBack?: () => void;
  buttonText?: string;
  type?: 'submit' | 'button';
  button?: React.ReactNode;
  disabled?: boolean;
  buttonBg?: string;
};

const BackHeader = ({
  title,
  disabled,
  onClick,
  buttonText,
  type = 'button',
  onBack,
  button,
  buttonBg = 'bg-white',
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className='flex items-center justify-between'>
      <div className='flex items-center gap-6'>
        <button
          type='button'
          onClick={() => {
            if (onBack) {
              return onBack();
            }
            navigate(-1);
          }}
          className={classNames(
            ` w-[44px] h-[44px] flex items-center justify-center rounded-10`,
            {
              'bg-white shadow-basic': buttonBg === 'bg-white',
              'bg-theme_white': buttonBg === 'bg-theme_white',
            },
          )}
        >
          <BackBigIcon />
        </button>
        <h2 className='text-title'>{t(title)}</h2>
      </div>
      {button ?? (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className='transition-all toucheck-btn bg-theme_check'
        >
          <span className='text-lg'>{t(buttonText ?? '')}</span>
        </button>
      )}
    </div>
  );
};

export default BackHeader;
