import { useEffect } from 'react';

import * as ChannelService from '@channel.io/channel-web-sdk-loader';
import { useRecoilValue } from 'recoil';

import { appNameState, toucheckAccountState } from '@context/atom';

const useChanneltalk = () => {
  const user = useRecoilValue(toucheckAccountState);
  const name = useRecoilValue(appNameState);

  const bootAnnoymous = () => {
    ChannelService.boot({
      pluginKey: import.meta.env.VITE_CHANNELTALK_PLUGIN_KEY,
    });
  };

  const bootMember = () => {
    ChannelService.boot({
      pluginKey: import.meta.env.VITE_CHANNELTALK_PLUGIN_KEY,
      memberId: `${user?.id ?? ''}`,
      profile: {
        // fill user's profile
        name: user?.name ?? '',
        mobileNumber: user?.phone_number ?? '',
        email: user?.email ?? '',
      },
    });
  };

  useEffect(() => {
    if (name && user) {
      if (name === 'toucheck') {
        if (user) {
          bootMember();
        } else {
          bootAnnoymous();
        }
      } else {
        ChannelService.hideChannelButton();
      }
    }
  }, [user, name]);
};

export default useChanneltalk;
