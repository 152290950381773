import { useQuery } from '@tanstack/react-query';

import { getFacilityStickerList } from '@services/toucheck/apiClient_toucheck';

const useFacilitySticker = (uuid: string) => {
  const fetchApi = async () => {
    const data = await getFacilityStickerList({
      page: 1,
      per_page: 100000,
      uuid,
    });
    return data;
  };

  return useQuery({
    queryKey: ['toucheck', 'sticker', uuid],
    queryFn: fetchApi,

    enabled: Boolean(uuid),
  });
};

export default useFacilitySticker;
