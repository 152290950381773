import {
  Account as AccountForToucheck,
  Facility,
  Organization as ToucheckOrg,
} from '@/model/touchcheck';
import { Organization } from '@/model/touchsiren';
import { atom, selector } from 'recoil';

import { transformFromDateBeforeDaysFormat } from '@utils/date';

import { AccountForMonitordogType } from '@models/monitordog/account/account';
import { NotificationAlarmType } from '@models/notification/notification_alarm';
import { AccountReliefType } from '@models/relief/account/account';
import { AccountType } from '@models/user/accounts';

export const userAuthState = atom({
  key: 'userAuthState',
  default: {},
});

// Set User State When changing The Service
export const userState = selector<
  | AccountType
  | AccountReliefType
  | AccountForMonitordogType
  | AccountForToucheck
  | undefined
>({
  key: 'userState',
  get: ({ get }) => {
    const appName = get(appNameState);
    const touchsirenAccount = get(touchsirenAccountState);
    const reliefAccount = get(reliefmapAccountState);
    const monitordogAccount = get(monitordogAccountState);
    const toucheckAccount = get(toucheckAccountState);
    switch (appName) {
      case 'reliefmap':
        return reliefAccount;
      case 'touchsiren':
        return touchsirenAccount;
      case 'monitordog':
        return monitordogAccount;
      case 'toucheck':
        return toucheckAccount;
      default:
        return touchsirenAccount;
    }
  },
});

// Integrate State
export const integrateState = atom({
  key: 'integrateState',
  default: {
    s: '',
    organization_id: '',
  },
});

/* ------------------------------ Account State Per Services ----------------------------- */

export const toucheckAccountState = atom<AccountForToucheck | undefined>({
  key: 'toucheckAccountState',
  default: undefined,
});
export const toucheckAdminState = selector({
  key: 'toucheckAdminState',
  get: ({ get }) => {
    const toucheck = get(toucheckAccountState);
    return toucheck?.grade === 'admin';
  },
});
export const touchsirenAccountState = atom<AccountType | undefined>({
  key: 'touchsirenAccountState',
  default: undefined,
});

export const reliefmapAccountState = atom<AccountReliefType | undefined>({
  key: 'reliefmapAccountState',
  default: undefined,
});

export const monitordogAccountState = atom<
  AccountForMonitordogType | undefined
>({
  key: 'monitordogAccountState',
  default: undefined,
});
/* -----------------------------------  ----------------------------------- */

export const appNameState = atom<string>({
  key: 'appNameState',
  default: '',
});

// Set Org State Per Services
export const appState = selector<Organization | ToucheckOrg | undefined>({
  key: 'appState',
  get: ({ get }) => {
    const appName = get(appNameState);
    const touchsirenOrg = get(touchsirenOrgState);
    const reliefOrg = get(reliefmapOrgState);
    const monitordogOrg = get(monitordogOrgState);
    const toucheckOrg = get(toucheckOrgState);
    switch (appName) {
      case 'reliefmap':
        return reliefOrg;
      case 'touchsiren':
        return touchsirenOrg;
      case 'monitordog':
        return monitordogOrg;
      case 'toucheck':
        return toucheckOrg;
      default:
        return touchsirenOrg;
    }
  },
});

export const appColorState = selector({
  key: 'appColorState',
  get: ({ get }) => {
    const appName = get(appNameState);
    switch (appName) {
      case 'reliefmap':
        return '#0062FF';
      case 'touchsiren':
        return '#0062FF';
      case 'monitordog':
        return '#0062FF';
      case 'toucheck':
        return '#00BD62';
      default:
        return '#0062FF';
    }
  },
});
export const appTextColorState = selector({
  key: 'appTextColorState',
  get: ({ get }) => {
    const appName = get(appNameState);
    switch (appName) {
      case 'toucheck':
        return 'text-theme_check';
      default:
        return 'text-theme_basic';
    }
  },
});
export const appBgColorState = selector({
  key: 'appBgColorState',
  get: ({ get }) => {
    const appName = get(appNameState);
    switch (appName) {
      case 'toucheck':
        return 'bg-theme_check';
      default:
        return 'bg-theme_basic';
    }
  },
});

/* ------------------------------- Org States ------------------------------- */
export const touchsirenOrgState = atom<Organization | undefined>({
  key: 'touchsirenOrgState',
  default: undefined,
});

export const toucheckOrgState = atom<ToucheckOrg | undefined>({
  key: 'toucheckOrgState',
  default: undefined,
});

export const reliefmapOrgState = atom<Organization | undefined>({
  key: 'reliefmapOrgState',
  default: undefined,
});

export const monitordogOrgState = atom<Organization | undefined>({
  key: 'monitordogOrgState',
  default: undefined,
});
/* -----------------------------------  ----------------------------------- */

export const emergencyState = atom<NotificationAlarmType | null>({
  key: 'emergencyState',
  default: null,
});

export const navStatusState = atom<boolean>({
  key: 'navStatusState',
  default: true,
});

export const dashboardDateState = atom<{ endDate: Date; startDate: Date }>({
  key: 'dashboardDateState',
  default: {
    endDate: new Date(),
    startDate: new Date(transformFromDateBeforeDaysFormat(new Date())),
  },
});

export const dashboardFacilityState = atom<Facility[]>({
  key: 'dashboardFacilityState',
  default: undefined,
});

export const dashboardFacilityUuid = selector<string[] | undefined>({
  key: 'dashboardFacilityUuid',

  get: ({ get }) => {
    const facilities = get(dashboardFacilityState);
    if (!facilities) return undefined;
    return facilities?.map((item) => item.uuid);
  },
});

export const dashboardFacilityids = selector<number[] | undefined>({
  key: 'dashboardFacilityids',
  get: ({ get }) => {
    const facilities = get(dashboardFacilityState);
    if (!facilities) return undefined;
    return facilities?.map((item) => item.id);
  },
});

export const modalState = atom<{ id: string; element: React.FC }[]>({
  key: 'modalState',
  default: [],
});
