import { Fragment, useRef } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';

type Props = {
  isOpen: boolean;
  title: string;
  isModal?: boolean;
  isImg?: boolean;
  isUpdated?: boolean;
  validate?: boolean;
  children: React.ReactNode;
  closeText?: string;
  confirmText?: string;
  mainColor?: string;
  titleAction?: React.ReactNode;

  noOverflow?: boolean;
  onClose: () => void;
  onConfirm?: () => void;
};

const BasicDialog = ({
  children,
  isOpen,
  title,
  isModal = false,
  isImg = false,
  isUpdated = false,
  closeText = '확인',
  confirmText,
  validate = false,
  titleAction,

  mainColor = 'bg-theme_basic',
  noOverflow = false,
  onClose,
  onConfirm,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initalRef = useRef<any>();
  const onSubmit = async () => {
    if (onConfirm) await onConfirm();
    if (!isUpdated) onClose();
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        initialFocus={initalRef}
        static
        as='div'
        className='relative z-[500]'
        onClose={() => {
          if (event instanceof PointerEvent === false) {
            onClose();
          }
        }}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out '
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in '
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black/25' />
        </Transition.Child>

        <div className='fixed inset-0'>
          <div className='flex items-center justify-center h-full text-center p-15'>
            <Transition.Child
              as={Fragment}
              enter='ease-out '
              enterFrom='opacity-0 '
              leave='ease-in'
              leaveTo='opacity-0 '
            >
              <Dialog.Panel
                className={classNames(
                  `custom-scrollbar flex flex-col max-h-modal
                   w-full text-left transition-all transform bg-white shadow-xl rounded-2xl`,
                  {
                    'max-w-4xl p-15 gap-10': isModal && isImg,
                    'max-w-7xl p-15 gap-10': isModal && !isImg,
                    'max-w-md p-10 gap-7.5': !isModal,
                    'overflow-y-scroll': !noOverflow,
                  },
                )}
              >
                {isModal ? (
                  <div className='flex items-center justify-between w-full bg-white'>
                    <Dialog.Title
                      as='h3'
                      ref={initalRef}
                      className={`font-bold text-3xl leading-[44px] text-[#333]`}
                    >
                      {title}
                    </Dialog.Title>
                    <div>{titleAction}</div>
                  </div>
                ) : (
                  <Dialog.Title
                    as='h3'
                    className={`font-bold leading-7.5 text-xl text-center w-full text-[#333]`}
                  >
                    {title}
                  </Dialog.Title>
                )}

                <div className=''>{children}</div>

                <div
                  className={`flex ${isModal ? 'justify-center gap-15' : 'justify-between gap-5'}`}
                >
                  <button
                    type='button'
                    className={classNames(
                      `justify-center w-full font-bold rounded-30 focus:outline-none`,
                      {
                        'max-w-60 h-15 text-lg': isModal,
                        'py-2': !isModal,
                        'border-2 bg-transparent text-text_gray': confirmText,
                        [`${mainColor} text-white`]: !confirmText,
                      },
                    )}
                    onClick={onClose}
                  >
                    {closeText}
                  </button>
                  {confirmText && (
                    <button
                      disabled={validate}
                      type='button'
                      onClick={onSubmit}
                      className={classNames(
                        `justify-center w-full font-bold text-white border
                         border-transparent rounded-30 ${mainColor} disabled:bg-theme_check_disabled focus:outline-none`,
                        {
                          'max-w-60 h-15 text-lg': isModal,
                          'py-1': !isModal,
                        },
                      )}
                    >
                      {confirmText}
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default BasicDialog;
