const AlarmIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='61'
      height='60'
      viewBox='0 0 61 60'
      fill='none'
    >
      <path
        d='M48.5006 35.2319L48.5 45H12.5L12.5006 35.2319C12.5006 25.1628 20.5596 17 30.5006 17C40.4418 17 48.5006 25.1626 48.5006 35.2319Z'
        fill='#FF5E57'
      />
      <path
        d='M48.5006 35.2319L48.5 45H12.5L12.5006 35.2319C12.5006 25.1628 20.5596 17 30.5006 17C40.4418 17 48.5006 25.1626 48.5006 35.2319Z'
        fill='url(#paint0_linear_272_19108)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.7329 2.6834V8.8299C27.7329 10.3109 28.9727 11.5133 30.4999 11.5133C32.0271 11.5133 33.2669 10.3109 33.2669 8.8299V2.6834C33.2669 1.20238 32.0271 0 30.4999 0C28.9727 0 27.7329 1.20238 27.7329 2.6834ZM6.30985 14.8684L10.7916 19.2145C11.8714 20.2618 13.6248 20.2618 14.7046 19.2145C15.7846 18.1673 15.7846 16.4669 14.7046 15.4197L10.2231 11.0734C9.14307 10.0263 7.38966 10.0263 6.30985 11.0734C5.23005 12.1206 5.23005 13.821 6.30985 14.8684ZM50.7769 11.0734L46.2952 15.4197C45.2154 16.4669 45.2154 18.1673 46.2952 19.2145C47.3752 20.2618 49.1286 20.2618 50.2084 19.2145L54.6901 14.8684C55.77 13.821 55.77 12.1206 54.6901 11.0734C53.6101 10.0263 51.8567 10.0263 50.7769 11.0734Z'
        fill='#FFEA83'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M55.5 49.4688C55.5 47.0007 53.0896 45 50.1161 45H10.8839C7.91039 45 5.5 47.0007 5.5 49.4688V51.531C5.5 53.9991 7.91039 56 10.8839 56H50.1161C53.0896 56 55.5 53.9991 55.5 51.531V49.4688Z'
        fill='#F4F4FC'
      />
      <rect x='28.5' y='23' width='4' height='12' rx='2' fill='white' />
      <rect x='28.5' y='23' width='4' height='12' rx='2' fill='white' />
      <rect x='28.5' y='37' width='4' height='4' rx='2' fill='white' />
      <rect x='28.5' y='37' width='4' height='4' rx='2' fill='white' />
      <defs>
        <linearGradient
          id='paint0_linear_272_19108'
          x1='12.5'
          y1='17'
          x2='39.6383'
          y2='51.8927'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FF8686' />
          <stop offset='1' stopColor='#FF1818' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AlarmIcon;
