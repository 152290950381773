const ProblemIcon = () => {
  return (
    <div className='w-[34px] h-[34px] bg-text_yellow/50 rounded-full flex items-center justify-center'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <g clipPath='url(#clip0_197_520)'>
          <path
            d='M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM13.2 16.8C13.2 17.4627 12.6627 18 12 18C11.3373 18 10.8 17.4627 10.8 16.8C10.8 16.1373 11.3373 15.6 12 15.6C12.6627 15.6 13.2 16.1373 13.2 16.8ZM13.2 12C13.2 12.6627 12.6627 13.2 12 13.2C11.3373 13.2 10.8 12.6627 10.8 12V7.2C10.8 6.53726 11.3373 6 12 6C12.6627 6 13.2 6.53726 13.2 7.2V12Z'
            fill='#F6C000'
          />
        </g>
        <defs>
          <clipPath id='clip0_197_520'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default ProblemIcon;
