const ServiceGuideIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        opacity='0.4'
        d='M9.6906 1.62837C11.1197 0.790543 12.8803 0.790543 14.3094 1.62837L19.6906 4.78326C21.1197 5.62109 22 7.16946 22 8.84512V15.1549C22 16.8305 21.1197 18.3789 19.6906 19.2167L14.3094 22.3716C12.8803 23.2095 11.1197 23.2095 9.6906 22.3716L4.3094 19.2167C2.88034 18.3789 2 16.8305 2 15.1549V8.84512C2 7.16946 2.88034 5.62109 4.3094 4.78326L9.6906 1.62837Z'
        fill='#00BD62'
      />
      <path
        d='M9.6001 9.44533C9.6001 8.09481 10.6746 7 12.0001 7C13.3256 7 14.4001 8.09481 14.4001 9.44533C14.4001 10.7958 13.3256 11.8907 12.0001 11.8907C12.0001 11.8907 11.9991 13 11.9991 13.9004'
        stroke='#00BD62'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx='12' cy='17' r='1' fill='#00BD62' />
    </svg>
  );
};

export default ServiceGuideIcon;
