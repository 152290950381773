import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import {
  monitordogAccountState,
  reliefmapAccountState,
  toucheckAccountState,
  touchsirenAccountState,
} from '@context/atom';

const Home = () => {
  const navigate = useNavigate();
  const touchsiren = useRecoilValue(touchsirenAccountState);
  const reliefmap = useRecoilValue(reliefmapAccountState);
  const monitordog = useRecoilValue(monitordogAccountState);
  const toucheck = useRecoilValue(toucheckAccountState);

  // 리다이렉트 경로 결정 로직을 함수로 분리
  const getRedirectPath = () => {
    if (touchsiren) return '/touchsiren/dashboard';
    if (reliefmap) return '/reliefmap/checks';
    if (monitordog) return '/monitordog/logs';
    if (toucheck) return '/toucheck/dashboard';
    return null; // 아무 상태도 없을 때는 null
  };

  useEffect(() => {
    const path = getRedirectPath();
    if (path) {
      navigate(path); // 유효한 경로가 있을 때만 navigate 호출
    }
  }, [touchsiren, reliefmap, monitordog, toucheck, navigate]); // navigate도 의존성 배열에 포함

  return null; // 아무런 UI를 렌더링하지 않음
};

export default Home;
