const NavInstall = ({ fill }: { fill: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        opacity='0.4'
        d='M15.5664 2.99489C13.402 1.68946 10.7165 1.66727 8.53162 2.93677C6.34676 4.20627 4.99855 6.57225 5 9.13442C5.06583 11.016 5.69859 12.8314 6.81233 14.334C8.14333 16.134 9.78243 17.6746 11.6508 18.8814C11.7693 18.9534 11.9036 18.9941 12.0415 19C12.1847 18.9777 12.3228 18.9289 12.4488 18.856C13.6726 18.0528 14.7998 17.1064 15.8075 16.0361C17.5533 14.19 18.95 11.8019 18.9998 9.26144C19.012 6.70418 17.7095 4.32691 15.5664 2.99489Z'
        fill={fill}
      />
      <ellipse opacity='0.4' cx='12' cy='21' rx='5' ry='1' fill={fill} />
      <path
        d='M11.991 11.6916C11.0286 11.6916 10.1611 11.1006 9.79354 10.1945C9.42602 9.28848 9.63097 8.2461 10.3127 7.5541C10.9944 6.8621 12.0184 6.65699 12.9066 7.03452C13.7948 7.41206 14.372 8.29779 14.3686 9.27813C14.3709 9.92039 14.1208 10.5369 13.6742 10.9902C13.2276 11.4436 12.6215 11.6961 11.991 11.6916Z'
        fill={fill}
      />
    </svg>
  );
};

export default NavInstall;
