const ApplyStickerIcon = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 2.90909C1 2.40701 1.40794 2 1.91116 2C3.40144 2 4.69678 3.02081 5.04196 4.46726L5.21249 5.18182L18.9469 5.18182C19.5445 5.1818 20.0528 5.18178 20.4641 5.21844C20.8922 5.2566 21.3202 5.34102 21.7114 5.57804C22.2605 5.91076 22.6746 6.4258 22.881 7.03277C23.028 7.46516 23.0178 7.90032 22.9622 8.3256C22.9089 8.73414 22.7986 9.22917 22.669 9.81125L22.0862 12.4277C21.9543 13.0199 21.8445 13.5128 21.7234 13.913C21.5967 14.3317 21.441 14.7093 21.1881 15.0546C20.8079 15.5737 20.2966 15.9829 19.7062 16.2406C19.3136 16.412 18.9103 16.4818 18.4732 16.5143C18.0553 16.5455 17.5493 16.5455 16.9412 16.5454H10.1158C9.5077 16.5455 9.00165 16.5455 8.58382 16.5143C8.14666 16.4818 7.74341 16.412 7.35077 16.2406C6.76041 15.9829 6.24908 15.5737 5.86889 15.0546C5.61603 14.7093 5.46024 14.3317 5.33355 13.913C5.21246 13.5128 5.10269 13.0199 4.97078 12.4276L3.60482 6.29481L3.2692 4.88839C3.11947 4.26097 2.55759 3.81818 1.91116 3.81818C1.40794 3.81818 1 3.41117 1 2.90909Z'
        fill='white'
      />
      <path
        d='M10.8709 19.8788C10.8709 21.0503 9.91905 22 8.74487 22C7.57069 22 6.61883 21.0503 6.61883 19.8788C6.61883 18.7073 7.57069 17.7576 8.74487 17.7576C9.91905 17.7576 10.8709 18.7073 10.8709 19.8788Z'
        fill='white'
      />
      <path
        d='M18.3121 22C19.4863 22 20.4381 21.0503 20.4381 19.8788C20.4381 18.7073 19.4863 17.7576 18.3121 17.7576C17.1379 17.7576 16.186 18.7073 16.186 19.8788C16.186 21.0503 17.1379 22 18.3121 22Z'
        fill='white'
      />
      <path
        d='M13.5706 13L13.5706 10.5714V8'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 10.5703L13.5714 10.5703L16.0002 10.5703'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ApplyStickerIcon;
