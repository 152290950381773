import ModalProvider from '@context/provider/ModalProvider';

import useChangeAppState from '@hooks/useChangeAppState';
import useInitAuth from '@hooks/useInitAuth';

import DefaultFallback from '@components/fallback/DefaultFallback';
import AlertDialog from '@components/popup/dialog/AlertDialog';
import Toast from '@components/popup/toast/Toast';
import DefaultLayout from '@components/styles/layout/DefaultLayout';

const RouteLayout = ({ children }: { children: React.ReactNode }) => {
  // 새로고침 시 정보 유지
  useInitAuth();

  // 서비스 변경 시 데이터 변경
  useChangeAppState();

  return (
    <DefaultLayout>
      <DefaultFallback>{children}</DefaultFallback>

      <Toast />
      <AlertDialog />
      <ModalProvider />
    </DefaultLayout>
  );
};

export default RouteLayout;
