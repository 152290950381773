import classNames from 'classnames';

import Header from '../../Header';
import Nav from '../../nav/Nav';

type Props = {
  children: React.ReactNode;
};

const DefaultLayout = ({ children }: Props) => {
  return (
    <div className='flex h-screen mx-auto overflow-hidden antialiased border-theme_border size-full max-w-max border-x'>
      <Nav />

      <div className='relative flex flex-col h-full min-h-screenView grow'>
        <Header />
        <main
          className={classNames(
            `w-full h-full flex bg-[#f1f4f7] overflow-hidden grow`,
          )}
        >
          <div className='flex-grow overflow-scroll scrollbar-none'>
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default DefaultLayout;
