const ReportAddressIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M2.83468 4.25L20.2353 21.8235M2.83468 4.25C3.4821 3.4854 4.44907 3 5.52941 3H11.4118M2.83468 4.25C2.31399 4.86495 2 5.66051 2 6.52941V19.4706C2 21.4198 3.58017 23 5.52941 23H18.4706C20.4198 23 22 21.4198 22 19.4706V14.1765M11.4118 14.1765L3.76471 21.8235'
        stroke='#8A92A6'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.6 5.03922V4.96703M22.44 4.95574C22.44 7.46009 18.6 10.7992 18.6 10.7992C18.6 10.7992 14.76 7.46009 14.76 4.95574C14.76 2.88107 16.4792 1.19922 18.6 1.19922C20.7208 1.19922 22.44 2.88107 22.44 4.95574Z'
        stroke='#8A92A6'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ReportAddressIcon;
