import { useTranslation } from 'react-i18next';

type Props = {
  showDetails: () => void;
};

const ListShowButton = ({ showDetails }: Props) => {
  const { t } = useTranslation();
  return (
    <button
      onClick={showDetails}
      className='px-4 py-1 font-bold text-sm leading-7.5
       transition-all duration-150 text-nowrap bg-theme_check/20 rounded-10
        text-theme_check hover:bg-theme_check hover:text-white'
    >
      {t('list_show')}
    </button>
  );
};

export default ListShowButton;
