const CheckMapIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
    >
      <path
        opacity='0.4'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.44653 4.95012C5.1259 4.81271 4.75769 4.84558 4.46647 5.03761C4.17526 5.22963 4 5.55513 4 5.90395V21.4971C4 21.9122 4.24739 22.2874 4.62895 22.4509L10.693 25.0498C10.9388 25.1552 11.2157 25.1615 11.4661 25.0676L18.032 22.6054L24.598 25.0676C24.9166 25.1872 25.2737 25.1428 25.5534 24.9489C25.8332 24.7551 26.0001 24.4363 26.0001 24.096V8.50281C26.0001 8.07024 25.7317 7.68304 25.3267 7.53115L18.3964 4.93229C18.1615 4.84419 17.9026 4.84419 17.6677 4.93229L11.1275 7.38484L5.44653 4.95012Z'
        fill='#00BD62'
      />
      <path
        d='M11 20V12.6667M18 17.3333V10'
        stroke='#00BD62'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CheckMapIcon;
