import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Dialog, Transition } from '@headlessui/react';

import useReadNotification from '@hooks/useReadNotification';

import { BasicModalType } from '@models/modal';
import { NotificationType } from '@models/notification/notification';

import CloseIcon from '@assets/CloseIcon';

type Props = BasicModalType & {
  data: NotificationType[];
};

const AlarmDialog = ({ isOpen, onClose, data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate } = useReadNotification();

  const transTypeToTexx = (type: string) => {
    switch (type) {
      case 'report':
        return 'alert_report';
      case 'check':
        return 'alert_check';
      default:
        '';
    }
    return '';
  };

  const onClick = (url: string, id: number) => {
    const splitedUrl = url
      .split(`${import.meta.env.VITE_BASE_HOME}touchsiren/`)
      .filter((item) => item)
      .join();

    mutate({
      ids: [id],
    });
    onClose();
    return navigate(splitedUrl);
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-[200]' onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black/25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex items-start justify-end min-h-full p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='flex flex-col w-full max-w-lg gap-5 p-10 mt-20 text-left align-middle transition-all transform bg-white shadow-xl max-h-[600px] rounded-2xl'>
                  <Dialog.Title className='flex justify-between w-full item-center'>
                    <span className='text-3xl font-bold'>{t('alert')}</span>
                    <div onClick={onClose} className='cursor-pointer'>
                      <CloseIcon />
                    </div>
                  </Dialog.Title>

                  <div className='flex flex-col gap-7.5 overflow-auto'>
                    {data && (
                      <>
                        {data.map((item) => (
                          <section
                            key={item.id}
                            onClick={() => onClick(item.url, item.id)}
                            className='p-7.5 flex flex-col w-full gap-5 rounded-20 border border-text_grayscale2 bg-white cursor-pointer'
                          >
                            <div className='flex flex-col gap-2.5'>
                              <div className='flex items-center justify-between w-full'>
                                <span className='text-sm font-bold text-text_gray'>
                                  {t(transTypeToTexx(item.type) || '')}
                                </span>
                                <span className='text-sm text-text_gray'>
                                  {item.created_at}
                                </span>
                              </div>
                              <h3
                                className='text-lg font-bold'
                                style={{ color: item.title_color }}
                              >
                                {t(item.title)}
                              </h3>
                            </div>

                            <div className='text-sm leading-7 whitespace-pre-line'>
                              {item.body}
                            </div>
                          </section>
                        ))}
                        <div className='text-lg text-[#B5B5B5] font-bold text-center'>
                          {t('alert_notice')}
                        </div>
                      </>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AlarmDialog;
