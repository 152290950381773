import { messaging, requestPermission } from '@/firebase_notification';
import { useQueryClient } from '@tanstack/react-query';
import { MessagePayload, onMessage } from 'firebase/messaging';
import { useSetRecoilState } from 'recoil';

import { emergencyState } from '@context/atom';

const useInitMessage = () => {
  const setEmerency = useSetRecoilState(emergencyState);
  const queryClient = useQueryClient();

  const typeToTitle = (type?: string) => {
    switch (type) {
      case 'report':
        return '신고가 접수되었습니다.';
      case 'check':
        return '비상벨 고장이 접수되었습니다.';
      default:
        return '접수되었습니다.';
    }
  };
  const onEmergency = (payload: MessagePayload) => {
    setEmerency(() => ({
      address: payload.data?.address || '',
      body: payload.data?.body || '',
      created_at: payload.data?.created_at || '',
      location: payload.data?.location || '',
      title: payload.data?.title || '',
      type: payload.data?.type || '',
      unique_id: payload.data?.unique_id || '',
      url: payload.data?.url || '',
      isOpen: true,
    }));
    setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: ['notifications'] });
    }, 500);

    const notificationTitle = `[터치사이렌]${typeToTitle(payload.data?.type)}`;
    const notificationOptions = {
      body: '서둘러 확인해 주세요.',
      icon: '/logo.png',
    };
    const notification = new Notification(
      notificationTitle,
      notificationOptions,
    );
    notification.onclick = function (event) {
      event.preventDefault();
      notification.close();
      window.open(payload.data?.url);
    };
  };
  const onInit = () => {
    // firebase message init
    requestPermission();

    onMessage(messaging, (payload) => {
      console.log(' Received foground message ', payload);
      onEmergency(payload);
    });
    // onBackgroundMessage(messaging, (payload) => {
    //   console.log(' Received background message ', payload);
    //   onEmergency(payload);
    // });
  };
  return { initFirebaseMessage: onInit };
};

export default useInitMessage;
