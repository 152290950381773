const HeaderBellForCheck = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.0123 23.3321C22.7173 23.3312 22.4239 23.2881 22.1416 23.2043C20.71 22.7148 19.3138 22.1315 17.9623 21.4584C15.9156 20.4981 13.6065 19.4211 12.1518 19.2827C12.1537 19.3076 12.1548 19.3325 12.1549 19.3575C12.156 19.4934 12.1316 19.6282 12.0834 19.754L9.4884 26.6695C9.29199 27.1807 8.95135 27.6148 8.51329 27.9121C8.07523 28.2094 7.56124 28.3553 7.04194 28.3298C6.66582 28.3316 6.29248 28.2613 5.93987 28.1223L5.72102 28.0393C5.04452 27.8186 4.47722 27.3235 4.14214 26.6615C3.80707 25.9994 3.73124 25.2237 3.93112 24.5027L5.7132 19.3306C5.75677 19.2026 5.8238 19.0851 5.91041 18.9848C5.99703 18.8845 6.10151 18.8034 6.21781 18.7463C6.33411 18.6892 6.45992 18.6571 6.58797 18.652C6.71601 18.6468 6.84375 18.6687 6.96378 18.7163C7.07508 18.7591 7.17698 18.8226 7.26531 18.9026L7.5 18.3327L6.20666 18.0453C6.18615 18.0692 6.1645 18.0924 6.14174 18.1146C5.93763 18.3143 5.6608 18.4265 5.37215 18.4265V18.3924H4.35344C1.95034 18.3924 0 16.7998 0 14.8409V10.1908C0 8.23199 1.95905 6.63937 4.35344 6.63937H5.37215C5.6608 6.63937 5.93763 6.75153 6.14174 6.95118C6.32555 7.13098 6.43683 7.36847 6.45714 7.62015L10.9028 6.9182C10.9018 6.90194 10.9012 6.88564 10.901 6.86932C10.8916 6.72602 10.9119 6.58233 10.9607 6.4469C11.0095 6.31148 11.0859 6.18711 11.1851 6.08129C11.2843 5.97547 11.4044 5.89038 11.5382 5.83115C11.6719 5.77191 11.8165 5.73975 11.9633 5.7366C13.3999 5.65143 15.7856 4.54426 17.8927 3.56484C19.2857 2.8753 20.7258 2.28056 22.2026 1.78485C22.6436 1.65829 23.1084 1.63241 23.5612 1.70918C24.0141 1.78596 24.4429 1.96336 24.8146 2.22772C25.1288 2.45198 25.3847 2.74533 25.5616 3.08411C25.7386 3.42289 25.8317 3.79761 25.8333 4.17804V20.8196C25.8313 21.2021 25.7373 21.5788 25.5588 21.9191C25.3803 22.2594 25.1224 22.5538 24.8059 22.7785C24.2863 23.1505 23.6561 23.345 23.0123 23.3321Z'
        fill='#8A92A6'
        fillOpacity='0.4'
      />
      <path
        d='M10.3228 20.4173H8.00155C7.20471 20.415 6.44133 20.1085 5.87874 19.565C5.31614 19.0215 5.00022 18.2854 5.00022 17.5179V7.50961C4.99542 7.12707 5.06951 6.74742 5.21819 6.39266C5.36687 6.0379 5.58718 5.7151 5.86636 5.44296C6.14554 5.17083 6.47803 4.95477 6.84455 4.80732C7.21107 4.65987 7.60434 4.58396 8.00155 4.58398H10.3228C10.7204 4.58397 11.1141 4.65982 11.4812 4.80717C11.8482 4.95452 12.1813 5.17043 12.4612 5.44245C12.7411 5.71447 12.9622 6.0372 13.1119 6.392C13.2616 6.74681 13.3368 7.12666 13.3332 7.50961V17.5528C13.3237 18.3157 13.0023 19.0443 12.4388 19.5805C11.8752 20.1167 11.115 20.4174 10.3228 20.4173Z'
        fill='#8A92A6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.8333 17.753C26.0959 17.6973 26.3546 17.6215 26.6068 17.5258C27.2687 17.2749 27.8731 16.8928 28.3836 16.4024C28.8942 15.9121 29.3004 15.3237 29.5778 14.6725C29.8553 14.0212 29.9984 13.3207 29.9984 12.6128C29.9984 11.9049 29.8553 11.2044 29.5778 10.5532C29.3004 9.90195 28.8942 9.31352 28.3836 8.8232C27.8731 8.33287 27.2687 7.95076 26.6068 7.69981C26.3546 7.60416 26.0959 7.52832 25.8333 7.47266V17.753Z'
        fill='#8A92A6'
      />
    </svg>
  );
};

export default HeaderBellForCheck;
