import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';

import useUpdateFacility from '@apps/toucheck/facility/hooks/useUpdateFacility';

import { getLatLng } from '@utils/map';

import { BasicModalType } from '@models/modal';

import BasicDialog from '@components/popup/dialog/BasicDialog';

type Props = BasicModalType & {
  defaultValue: { name: string; address: string };
  name: 'name' | 'address';
  title: string;
  uuid?: string;
};

const EditModal = ({
  isOpen,
  defaultValue,
  name,
  title,
  uuid,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const { register, watch, setValue, reset, getValues } = useForm({
    defaultValues: defaultValue,
  });
  const isLimited = name === 'name';
  const value = watch(name);

  const { mutate } = useUpdateFacility();
  const closeModal = () => {
    onClose();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    // 입력 값이 maxLength를 넘지 않으면 입력 허용
    if (value.length <= 20) {
      setValue(name, value); // React Hook Form의 setValue 사용
    }
  };
  useEffect(() => {
    if (defaultValue) {
      reset(defaultValue);
    }
  }, [defaultValue]);

  const onEdit = async () => {
    const values = getValues();
    const addressInfo = await getLatLng({ address: values?.address });
    const latlng = addressInfo?.results[0]?.geometry?.location;
    if (uuid) {
      mutate({
        uuid,
        body: {
          name: values.name,
          address: values.address,
          lat: latlng?.lat ?? 0,
          lng: latlng?.lng ?? 0,
        },
      });
    }
  };
  return (
    <>
      <BasicDialog
        title={title}
        confirmText={t('confirm')}
        closeText={t('close')}
        isOpen={isOpen}
        mainColor='bg-theme_check'
        onConfirm={onEdit}
        onClose={closeModal}
      >
        <label>
          <span className='font-bold text-text_gray leading-7.5'>
            {t(name)}
          </span>
          <div className='h-4' />
          <div className='flex items-center user-input'>
            <input
              {...register(name, {
                required: true,
                maxLength: isLimited ? 20 : undefined,
              })}
              onChange={isLimited ? handleInputChange : undefined}
              className='w-full text-sm focus:outline-none placeholder:text-text_grayscale2'
              value={isLimited ? value : undefined}
              placeholder={t('input_placeholder')}
            />
            {isLimited && (
              <div
                className={classNames('text-nowrap text-sm leading-7.5 ', {
                  'text-text_red': isLimited && value.length >= 20,
                  'text-text_grayscale2': isLimited && value.length < 20,
                })}
              >
                {value.length}/{t('letter', { count: 20 })}
              </div>
            )}
          </div>
        </label>
      </BasicDialog>
    </>
  );
};

export default EditModal;
