import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getScheduleList } from '@services/toucheck/apiClient_toucheck';

type Params = {
  page: number;
  per_page: number;
  f_facility_uuids?: string[];
  f_check_list_uuids?: string[];
  f_uuids?: string[];
  count: number;
};

const useGetScheduleList = (params: Params) => {
  const fetchApi = async () => {
    const data = await getScheduleList(params);
    return data;
  };
  return useQuery({
    queryKey: ['toucheck', 'schedules', `${params.page}`, params],
    queryFn: fetchApi,
    placeholderData: keepPreviousData,
  });
};

export default useGetScheduleList;
