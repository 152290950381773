import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type Props = {
  page: number;
  total?: number;
  limit: number;
  isLast?: boolean;
  count: number;
  isSave?: boolean;
  setPage: (value: number) => void;
};

const PageNation = ({ page, limit, count, isSave = true, setPage }: Props) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const numPages = Math.ceil(count / limit) || 1;

  const pageGroup = Math.ceil(page / 5);
  const firstPage = (pageGroup - 1) * 5 + 1;

  const buttonStyle = `px-4 py-1 rounded-10 font-bold shadow-button disabled:text-[#d6d6d6] focus:outline-none`;

  const setParams = (page: number) => {
    if (isSave) {
      navigate(`?page=${page}`, { replace: true });
    }
  };

  return (
    <div className='flex items-center gap-4'>
      <button
        onClick={() => {
          setPage(page - 1);
          setParams(page - 1);
        }}
        disabled={page === 1}
        className={`${buttonStyle} bg-white text-text_gray`}
      >
        {t('prev')}
      </button>
      {Array(numPages)
        .fill(0)
        .slice(firstPage - 1, firstPage + (5 - 1))
        .map((_, i) => (
          <button
            key={i + 1}
            onClick={() => {
              setPage(i + firstPage);
              setParams(i + firstPage);
            }}
            className={`${buttonStyle} ${page === firstPage + i ? 'text-white bg-text_gray' : 'bg-white text-text_gray'}`}
          >
            {firstPage + i}
          </button>
        ))}
      <button
        onClick={() => {
          setPage(page + 1);
          setParams(page + 1);
        }}
        disabled={page === numPages}
        className={`${buttonStyle} bg-white text-text_gray`}
      >
        {t('next')}
      </button>
    </div>
  );
};

export default PageNation;
