const ProfileIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.61507 15.3435C2.11562 16.5742 0.5 21.8426 0.5 23.7506C0.5 25.9173 2.58333 25.9173 2.58333 25.9173H23.4167C23.4167 25.9173 25.5 25.9173 25.5 23.7506C25.5 21.8426 23.8844 16.5742 16.3849 15.3435C15.3683 15.1766 14.2435 15.084 13 15.084C11.7565 15.084 10.6317 15.1766 9.61507 15.3435Z'
        fill='#8A92A6'
      />
      <circle cx='13' cy='6.33398' r='6.25' fill='#8A92A6' fillOpacity='0.4' />
    </svg>
  );
};

export default ProfileIcon;
