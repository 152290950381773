const EnrollAnswerDeleteIcon = ({ color = '#00BD62' }: { color?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M16 24H8C5.79086 24 4 22.2091 4 20V5H20V20C20 22.2091 18.2091 24 16 24Z'
        fill={color}
      />
      <path
        d='M8.37052 1.36064C8.94594 0.510586 9.91466 0 10.952 0H13.0496C14.087 0 15.0557 0.510587 15.6311 1.36064L16.9665 3.33333H7.03516L8.37052 1.36064Z'
        fill={color}
      />
      <path
        d='M9.5 11L9.5 18'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2 4L22 4'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.5 11L14.5 18'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default EnrollAnswerDeleteIcon;
