import { useMutation, useQueryClient } from '@tanstack/react-query';

import { postPaymentBillingKey } from '@services/toucheck/apiClient_toucheck';

const usePostBillingKey = () => {
  const queryClient = useQueryClient();
  const fetchApi = async (body: { auth_key: string }) => {
    const data = await postPaymentBillingKey(body);
    return data;
  };
  return useMutation({
    mutationFn: fetchApi,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['toucheck', 'billings'] });
    },
  });
};

export default usePostBillingKey;
