import {
  DisplayLogListRes,
  IntegrateFirebaseRequest,
  UpdateAccountRequest,
} from '@/model/monitordog';
import {
  CreateAccountWithFirebaseUidRequest,
  IntegrateFirebaseUidRequest,
} from '@/model/reliefmap';
import {
  Account,
  FullToken,
  Organization,
  RegisterAccountRequest,
  ReliefMapPostsRes,
} from '@/model/touchsiren';
import { stringify } from 'qs';

import { AccountForMonitordogType } from '@models/monitordog/account/account';
import { DisplayLogListReq } from '@models/monitordog/display_logs/display_logs_req';
import { AccountReliefType } from '@models/relief/account/account';
import { UpdateAccountReliefReq } from '@models/relief/account/updateAccountReliefReq';
import { ReliefPostDownloadReq } from '@models/relief/posts/reliefPostDownloadReq';
import { ReliefPostsReq } from '@models/relief/posts/reliefPostsReq';

import API from './axios';
import API_MONITORDOG from './axios_monitordog';
import API_RELIEF from './axios_relief';

/* ------------------------------- Common API ------------------------------- */

// Get Members Account
export const getAccounts = async (
  page: number,
  perPage: number,
): Promise<Account[]> => {
  const { data } = await API.get(
    `accounts?${stringify({
      page,
      per_page: perPage,
    })}`,
  );
  return data;
};

// Get Organization
export const getOrganization = async (): Promise<Organization> => {
  const { data } = await API.get('organization/my');
  return data;
};

export const getAccountMe = async (): Promise<Account> => {
  const { data } = await API.get('account/me');
  return data;
};

/* ----------------------------- TouchSiren API ----------------------------- */

export const registerAccountTouchsiren = async (
  body: RegisterAccountRequest,
): Promise<Account> => {
  const { data } = await API.post(`account/register`, {
    ...body,
  });
  return data;
};

export const createAuthForTouchsiren = async ({
  firebase_token,
}: {
  firebase_token: string;
}): Promise<FullToken> => {
  const { data } = await API.post('auth', {
    firebase_token: firebase_token,
  });
  return data;
};

/* ------------------------------ TOUCHECK API ------------------------------ */

/* ------------------------------ ReliefMap API ----------------------------- */
export const registerAccountForReliefmap = async (
  body: CreateAccountWithFirebaseUidRequest,
) => {
  const { data } = await API_RELIEF.post('accounts/fid', {
    ...body,
  });
  return data;
};

export const integrateForReliefmap = async (
  body: IntegrateFirebaseUidRequest,
): Promise<AccountReliefType> => {
  const { data } = await API_MONITORDOG.post('accounts/fid/integrate', {
    ...body,
  });
  return data;
};

export const createAuthForReliefmap = async ({
  firebase_token,
}: {
  firebase_token: string;
}): Promise<FullToken> => {
  const { data } = await API_RELIEF.post(
    `auth/firebase?firebase_token=${firebase_token}`,
  );
  return data;
};

// Get Relief Posts
export const getReliefPosts = async (
  params: ReliefPostsReq,
): Promise<ReliefMapPostsRes> => {
  const { data } = await API_RELIEF.get(
    `posts/emails?${stringify(
      { ...params },

      { skipNulls: true, arrayFormat: 'repeat', encode: false },
    )}`,
  );
  return data;
};

export const getReliefPostsDownload = async (
  params: ReliefPostDownloadReq,
): Promise<string> => {
  const { data } = await API_RELIEF.get(
    `posts/emails/download?${stringify(
      { ...params },

      { skipNulls: true, arrayFormat: 'repeat', encode: false },
    )}`,
    {
      responseType: 'arraybuffer',
    },
  );
  const url = URL.createObjectURL(new Blob([data]));
  return url;
};

export const getMyOrgForRelief = async (): Promise<Organization> => {
  const { data } = await API_RELIEF.get(`organization/my`);
  return data;
};

export const getMyAccountForRelief = async (): Promise<AccountReliefType> => {
  const { data } = await API_RELIEF.get('accounts/me');
  return data;
};

export const putAccountForRelief = async (body: UpdateAccountReliefReq) => {
  const { data } = await API_RELIEF.put('accounts/me', {
    ...body,
  });
  return data;
};

export const inviteReliefmapUser = async (email: string) => {
  const { data } = await API_RELIEF.put(
    `organization/my/invite?email=${email}`,
  );
  return data;
};

export const deleteReliefUser = async ({
  member_id,
}: {
  member_id: number;
}) => {
  const { data } = await API_RELIEF.delete(
    `organization/my/members/${member_id}`,
  );
  return data;
};

/* ----------------------------- MonitorDog API ----------------------------- */

export const registerAccountForMonitordog = async (
  body: RegisterAccountRequest,
): Promise<Account> => {
  const { data } = await API_MONITORDOG.post(`account/register`, {
    ...body,
  });
  return data;
};

export const integrateForMonitordog = async (
  body: IntegrateFirebaseRequest,
): Promise<Account> => {
  const { data } = await API_MONITORDOG.post('account/firebase/integrate', {
    ...body,
  });
  return data;
};

export const createAuthForMonitordog = async ({
  firebase_token,
}: {
  firebase_token: string;
}): Promise<FullToken> => {
  const { data } = await API_MONITORDOG.post(`auth`, {
    firebase_token,
  });
  return data;
};

export const getAccountsMonitordog = async ({
  page,
  per_page,
}: {
  page: number;
  per_page: number;
}): Promise<Account[]> => {
  const { data } = await API_MONITORDOG.get(
    `accounts?page=${page}&per_page=${per_page}`,
  );
  return data;
};

export const updateMemberForMonitordog = async (
  id: number,
  body: UpdateAccountRequest,
): Promise<Account> => {
  const { data } = await API_MONITORDOG.put(`account/${id}`, {
    ...body,
  });
  return data;
};

export const deleteMemberForMonitordog = async (
  id: number,
): Promise<Account> => {
  const { data } = await API_MONITORDOG.delete(`account/${id}`);
  return data;
};

export const getOrgForMonitordog = async (): Promise<Organization> => {
  const { data } = await API_MONITORDOG.get('organization/my');
  return data;
};

export const getAccountForMonitordog =
  async (): Promise<AccountForMonitordogType> => {
    const { data } = await API_MONITORDOG.get('account/me');
    return data;
  };

// Put Account Me
export const updateAccountForMonitordog = async (
  body: UpdateAccountRequest,
) => {
  const { data } = await API_MONITORDOG.put('account/me', {
    ...body,
  });
  return data;
};

export const inviteForMonitordog = async (email: string) => {
  const { data } = await API_MONITORDOG.post(`account/invite`, {
    email,
  });
  return data;
};

export const getDisplayLogs = async (
  params: DisplayLogListReq,
): Promise<DisplayLogListRes> => {
  const { data } = await API_MONITORDOG.get(
    `display_logs?${stringify(
      { ...params },

      { skipNulls: true, arrayFormat: 'repeat', encode: false },
    )}`,
  );
  return data;
};
