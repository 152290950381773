const ScheduleFacIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
    >
      <g opacity='0.4'>
        <path
          d='M20.5 15C20.5 16.1046 19.6046 17 18.5 17H10.5V20C10.5 21.1046 11.3954 22 12.5 22H22.5C23.6046 22 24.5 21.1046 24.5 20V12C24.5 10.8954 23.6046 10 22.5 10H20.5V15Z'
          fill='#00BD62'
        />
      </g>
      <path
        d='M7.5 18H19.5C20.6046 18 21.5 17.1046 21.5 16V8C21.5 6.89543 20.6046 6 19.5 6H7.5V18Z'
        fill='#00BD62'
      />
      <rect
        opacity='0.4'
        x='5.5'
        y='4'
        width='2'
        height='22'
        rx='1'
        fill='#00BD62'
      />
    </svg>
  );
};

export default ScheduleFacIcon;
