import { ImgHTMLAttributes, useState } from 'react';

import { motion, AnimatePresence, MotionProps } from 'framer-motion';

type Props = ImgHTMLAttributes<HTMLImageElement> & {
  src: string;
  alt: string;
  motionProps?: MotionProps;
};

const ImageWithSkeleton = ({ src, alt, ...props }: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className='relative inline-block w-full h-full'>
      <AnimatePresence>
        {!isLoaded && (
          <motion.div
            className={`absolute top-0 left-0 w-full h-full bg-text_grayscale2/50 ${props.className}`}
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          />
        )}
      </AnimatePresence>
      <motion.img
        src={src}
        alt={alt}
        onLoad={() => setIsLoaded(true)}
        initial={{ opacity: 0 }}
        animate={{ opacity: isLoaded ? 1 : 0 }}
        transition={{ duration: 0.5 }}
        className={`w-full h-full ${props.className}`}
        onClick={props.onClick}
      />
    </div>
  );
};

export default ImageWithSkeleton;
