import { useTranslation } from 'react-i18next';

import { BasicModalType } from '@models/modal';

import Loader from '@components/loader/Loader';
import BasicDialog from '@components/popup/dialog/BasicDialog';

type Props = BasicModalType & {
  title: string;
  content: React.ReactNode;
  isLoading?: boolean;
  isSingle?: boolean;
  onConfirm?: () => void;
};

const SimpleDialog = ({
  isOpen,
  isLoading,
  title,
  content,
  isSingle = false,
  onClose,
  onConfirm,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {isLoading && <Loader isOpen={isLoading} />}
      <BasicDialog
        title={title}
        isOpen={isOpen}
        onConfirm={onConfirm}
        onClose={onClose}
        confirmText={isSingle ? undefined : t('confirm')}
        closeText={t('cancel')}
        mainColor='bg-theme_check'
      >
        <div className='text-center whitespace-pre-line break-keep'>
          {content}
        </div>
      </BasicDialog>
    </>
  );
};

export default SimpleDialog;
