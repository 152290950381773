type Props = {
  title: string;
  content: string;
};

const ToastStatus = ({ title, content }: Props) => {
  return (
    <div className='flex flex-col gap-1.5 ml-3'>
      <h1 className='text-lg font-bold text-white'>{title}</h1>
      <span className='text-sm text-white'>{content}</span>
    </div>
  );
};

export default ToastStatus;
