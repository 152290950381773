import Lottie from 'react-lottie';

import loading from '@assets/loading.json';

const SuspenseFallback = () => {
  return (
    <div className='flex size-full items-center justify-center'>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: loading,
        }}
        width={240}
        height={240}
      />
    </div>
  );
};

export default SuspenseFallback;
