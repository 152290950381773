import { useEffect } from 'react';

import Cookies from 'js-cookie';

import useLoginForMonitordog from '@apps/user/hooks/useLoginForMonitordog';
import useLoginForReliefmap from '@apps/user/hooks/useLoginForReliefmap';
import useLoginForToucheck from '@apps/user/hooks/useLoginForToucheck';
import useLoginForTouchsiren from '@apps/user/hooks/useLoginForTouchsiren';

import { TOKEN } from '@constants/auth';

const useInitAuth = () => {
  const touchsirenToken = Cookies.get(TOKEN.TOUCHSIREN_TOKEN);
  const reliefToken = Cookies.get(TOKEN.RELIEFMAP_TOKEN);
  const monitordogToken = Cookies.get(TOKEN.MONITORDOG_TOKEN);
  const toucheckToken = Cookies.get(TOKEN.TOUCHECK_TOKEN);

  const { onLoginForReliefmap } = useLoginForReliefmap();
  const { onLoginForMonitordog } = useLoginForMonitordog();
  const { onLoginForTouchsiren } = useLoginForTouchsiren();
  const { onLoginForToucheck } = useLoginForToucheck();

  useEffect(() => {
    reliefToken && onLoginForReliefmap(reliefToken);
    monitordogToken && onLoginForMonitordog(monitordogToken);
    touchsirenToken && onLoginForTouchsiren(touchsirenToken);
    toucheckToken && onLoginForToucheck(toucheckToken);
  }, []);
};

export default useInitAuth;
