const ReportPositionIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M10.7071 21.7503C11.427 22.4778 12.573 22.4778 13.2929 21.7503C15.6715 19.3466 20.5 13.9343 20.5 9.6087C20.5 4.85424 16.6944 1 12 1C7.30558 1 3.5 4.85424 3.5 9.6087C3.5 13.9343 8.3285 19.3465 10.7071 21.7503Z'
        stroke='#8A92A6'
        strokeWidth='2'
      />
      <path
        d='M15 9C15 10.6569 13.6569 12 12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9Z'
        stroke='#8A92A6'
        strokeWidth='2'
      />
    </svg>
  );
};

export default ReportPositionIcon;
