import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useSetRecoilState } from 'recoil';

import { appNameState } from '@context/atom';

const useChangeAppState = () => {
  const { pathname } = useLocation();

  const setAppName = useSetRecoilState(appNameState);

  const whatIsApp = () => {
    const appName = pathname.split('/').filter((item) => item)[0];

    switch (appName) {
      case 'reliefmap':
        return 'reliefmap';
      case 'monitordog':
        return 'monitordog';
      case 'touchsiren':
        return 'touchsiren';
      case 'toucheck':
        return 'toucheck';
      default:
        return '';
    }
  };

  useLayoutEffect(() => {
    if (!pathname) return () => {};
    const appName = whatIsApp();
    setAppName(appName);
  }, [pathname]);
};

export default useChangeAppState;
