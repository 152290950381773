export const fallbackLng = 'en';
export const languages = [fallbackLng, 'ko', 'es'];
export const defaultNS = 'translation';
export const cookieName = 'i18next';

export function getOptions(
  lng = fallbackLng,
  ns: string | string[] = defaultNS,
) {
  return {
    debug: true,
    supportedLngs: languages,
    preload: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  };
}
