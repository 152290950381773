import {
  Account,
  AccountListRes,
  CheckList,
  CreateCheckListReq,
  CreateFacilityReq,
  CreateScheduleReq,
  Facility,
  FullToken,
  ListCheckListRes,
  ListFacilityRes,
  ListSchedule,
  ListWorker,
  Schedule,
  UpdateFacilityReq,
  ListIssue,
  Issue,
  CreateIssueMemoReq,
  IssueMemo,
  DeleteIssueMemoReq,
  UpdateIssueReq,
  CreateWorkerReq,
  WorkerRes,
  UpdateMyAccountRequest,
  UpdateAccountRequest,
  CheckResult,
  CreateStickerRequest,
  StickerRequest,
  IntegrateFirebaseRequest,
  ListStickerItem,
  AccountInviteLog,
  Organization,
  DeleteIssueReq,
  WorkerSchedule,
  UpdateMyOrgRequest,
  WorkerScheduleList,
  CheckCountRes,
  IssueCountRes,
  DailyCountWorkerSchedule,
  BestWorkerRes,
  DailyCounts,
  Division,
  RegisterAccountRequest,
  Payment,
  UpdateWorkerReq,
  CreateInitialAccountRequest,
  UpdateAccountListReq,
  UpdateDivisionPermissionReq,
  UpdateAccountPermissionReq,
  BillingKeyInfo,
} from '@/model/touchcheck';
import { ProblemFacilitiesRes } from '@/model/touchcheck/src/model/problem-facilities-res';
import { stringify } from 'qs';

import { IssueListRes } from '@models/issue/issueListRes';

import API_TOUCHECK from './axios_toucheck';

export const createInitialAccount = async (
  body: CreateInitialAccountRequest,
): Promise<Account> => {
  const { data } = await API_TOUCHECK.post(`account/initial`, {
    ...body,
  });
  return data;
};

export const registerAccountToucheck = async (
  body: RegisterAccountRequest,
): Promise<Account> => {
  const { data } = await API_TOUCHECK.post(`account/register`, {
    ...body,
  });
  return data;
};

export const getOrganizationMy = async (): Promise<Organization> => {
  const { data } = await API_TOUCHECK.get(`organization/my`);
  return data;
};

export const updateOrg = async (
  params: UpdateMyOrgRequest,
): Promise<Organization> => {
  const { data } = await API_TOUCHECK.put(`organization/my`, {
    ...params,
  });
  return data;
};

export const inviteAccount = async ({
  email,
}: {
  email: string;
}): Promise<AccountInviteLog> => {
  const { data } = await API_TOUCHECK.post(`account/invite`, {
    email,
  });
  return data;
};

export const integrateForToucheck = async (
  body: IntegrateFirebaseRequest,
): Promise<Account> => {
  const { data } = await API_TOUCHECK.post(`account/firebase/integrate`, {
    ...body,
  });
  return data;
};

// Account & Auth
export const createAuthForToucheck = async ({
  firebase_token,
}: {
  firebase_token: string;
}): Promise<FullToken> => {
  const { data } = await API_TOUCHECK.post('auth', {
    firebase_token: firebase_token,
  });
  return data;
};

export const getMembersForToucheck = async (params: {
  page: number;
  per_page: number;
  f_grade?: string;
  f_division_uuids?: string[];
  f_name?: string;
}): Promise<AccountListRes> => {
  const { data } = await API_TOUCHECK.get(
    `account/organization/my?${stringify(
      {
        ...params,
      },
      {
        skipNulls: true,
        arrayFormat: 'repeat',
        encode: false,
      },
    )}`,
  );
  return data;
};

export const updateAccountList = async (body: UpdateAccountListReq) => {
  await API_TOUCHECK.put(`account/list`, {
    ...body,
  });
};

export const getAccountForToucheck = async (): Promise<Account> => {
  const { data } = await API_TOUCHECK.get('account/me');
  return data;
};

export const updateAccountMe = async (
  body: UpdateMyAccountRequest,
): Promise<Account> => {
  const { data } = await API_TOUCHECK.put('account/me', {
    ...body,
  });
  return data;
};
export const getAcountForId = async (id: number): Promise<Account> => {
  const { data } = await API_TOUCHECK.get(`account/${id}`);
  return data;
};

export const updateAccount = async (
  body: UpdateAccountRequest & { id: number },
): Promise<Account> => {
  const { data } = await API_TOUCHECK.put(`account/${body.id}`, {
    ...body,
  });
  return data;
};

export const deleteAccount = async (id: number): Promise<Account> => {
  const { data } = await API_TOUCHECK.delete(`account/${id}`);
  return data;
};

export const updateAccountPermission = async (
  body: UpdateAccountPermissionReq,
) => {
  await API_TOUCHECK.put(`auth/account/permission`, {
    ...body,
  });
};
export const updateDivisionPermission = async (
  body: UpdateDivisionPermissionReq,
) => {
  await API_TOUCHECK.put(`auth/division/permission`, {
    ...body,
  });
};

export const getWorkersList = async ({
  page,
  per_page,
}: {
  page: number;
  per_page: number;
}): Promise<ListWorker> => {
  const { data } = await API_TOUCHECK.get(
    `worker/list?page=${page}&per_page=${per_page}&account_type=admin`,
  );
  return data;
};

export const createWorker = async (
  body: CreateWorkerReq,
): Promise<WorkerRes> => {
  const { data } = await API_TOUCHECK.post(`worker`, {
    ...body,
  });
  return data;
};
export const updateWorker = async (
  uuid: string,
  body: UpdateWorkerReq,
): Promise<WorkerRes> => {
  const { data } = await API_TOUCHECK.put(`worker/${uuid}`, {
    ...body,
  });
  return data;
};
export const deleteWorker = async (uuid: string): Promise<WorkerRes> => {
  const { data } = await API_TOUCHECK.delete(`worker/${uuid}`);
  return data;
};

// CheckList
export const createChecklist = async ({
  params,
}: {
  params: CreateCheckListReq;
}): Promise<CheckList> => {
  const { data } = await API_TOUCHECK.post('check_list', {
    ...params,
  });
  return data;
};

export const updateChecklist = async (
  uuid: string,
  body: CreateCheckListReq,
): Promise<CheckList> => {
  const { data } = await API_TOUCHECK.put(`check_list/${uuid}`, {
    ...body,
  });
  return data;
};

export const deleteChecklist = async ({
  uuid,
}: {
  uuid: string;
}): Promise<true> => {
  const { data } = await API_TOUCHECK.delete(`check_list/${uuid}`);
  return data;
};

export const getAuthorizedChecklists = async ({
  division_uuid,
}: {
  division_uuid: string;
}): Promise<ListCheckListRes> => {
  const { data } = await API_TOUCHECK.get(
    `check_lists/authorized/division?division_uuid=${division_uuid}`,
  );
  return data;
};

export const createSchedule = async (
  params: CreateScheduleReq,
): Promise<Schedule> => {
  const { data } = await API_TOUCHECK.post('schedule', {
    ...params,
  });
  return data;
};

export const updateSchedule = async ({
  uuid,
  params,
}: {
  uuid: string;
  params: CreateScheduleReq;
}): Promise<Schedule> => {
  const { data } = await API_TOUCHECK.put(`schedule/${uuid}`, {
    ...params,
  });
  return data;
};

export const deleteSchedule = async ({
  uuid,
}: {
  uuid: string;
}): Promise<boolean> => {
  const { data } = await API_TOUCHECK.delete(
    `schedule/${uuid}?account_type=admin`,
  );
  return data;
};

export const getScheduleForId = async ({
  uuid,
}: {
  uuid: string;
}): Promise<Schedule> => {
  const { data } = await API_TOUCHECK.get(
    `schedule/${uuid}?account_type=admin`,
  );
  return data;
};

export const getScheduleList = async ({
  page,
  per_page,
  f_facility_uuids,
  f_check_list_uuids,
  f_uuids,
}: {
  page: number;
  per_page: number;
  f_facility_uuids?: string[];
  f_check_list_uuids?: string[];
  f_uuids?: string[];
}): Promise<ListSchedule> => {
  const { data } = await API_TOUCHECK.get(
    `schedule/list?${stringify(
      {
        page,
        per_page,
        f_facility_uuids,
        f_check_list_uuids,
        account_type: 'admin',
        f_uuids,
      },
      {
        skipNulls: true,
        arrayFormat: 'repeat',
        encode: false,
      },
    )}`,
  );
  return data;
};

export const getChecklistsForToucheck = async ({
  page,
  per_page,
}: {
  page: number;
  per_page: number;
}): Promise<ListCheckListRes> => {
  const { data } = await API_TOUCHECK.get(
    `check_lists?page=${page}&per_page=${per_page}`,
  );
  return data;
};

export const getFacilitiesAll = async (): Promise<Facility[]> => {
  const { data } = await API_TOUCHECK.get(`facility/list/all`);
  return data;
};

export const getChecklistAll = async (): Promise<ListCheckListRes> => {
  const { data } = await API_TOUCHECK.get(`check_list/all`);
  return data;
};

export const getCheckForIdForToucheck = async (
  uuid: string,
): Promise<CheckList> => {
  const { data } = await API_TOUCHECK.get(`check_list/${uuid}`);
  return data;
};

export const updateCheckForIdForToucheck = async ({
  body,
  uuid,
}: {
  body: CreateCheckListReq;
  uuid: string;
}): Promise<CheckList> => {
  const { data } = await API_TOUCHECK.put(`check_list/${uuid}`, {
    ...body,
  });
  return data;
};

export const deleteCheckForIdForToucheck = async (
  uuid: string,
): Promise<boolean> => {
  const { data } = await API_TOUCHECK.delete(`check_list/${uuid}`);
  return data;
};

// Facility

export const createFacility = async ({
  params,
}: {
  params: CreateFacilityReq;
}): Promise<Facility> => {
  const { data } = await API_TOUCHECK.post('facility', {
    ...params,
  });
  return data;
};

export const getFacilitiesForToucheck = async ({
  page,
  per_page,
  facility_ids,
}: {
  page: number;
  per_page: number;
  facility_ids?: number[];
}): Promise<ListFacilityRes> => {
  const { data } = await API_TOUCHECK.get(
    `facility/list?${stringify(
      { page, per_page, facility_ids, account_type: 'admin' },
      { skipNulls: true, arrayFormat: 'repeat', encode: false },
    )}`,
  );
  return data;
};

export const getFacilityForIdForToucheck = async (
  uuid: string,
): Promise<Facility> => {
  const { data } = await API_TOUCHECK.get(
    `facility/${uuid}?account_type=admin`,
  );
  return data;
};

export const updateFacilityForIdForToucheck = async ({
  body,
  uuid,
}: {
  body: UpdateFacilityReq;
  uuid: string;
}): Promise<Facility> => {
  const { data } = await API_TOUCHECK.put(`facility/${uuid}`, {
    ...body,
  });
  return data;
};

export const deleteFacilityForIdForToucheck = async (
  uuid: string,
): Promise<boolean> => {
  const { data } = await API_TOUCHECK.delete(`facility/${uuid}`);
  return data;
};

export const getAuthorizedFacilities = async ({
  division_uuid,
}: {
  division_uuid: string;
}): Promise<ListFacilityRes> => {
  const { data } = await API_TOUCHECK.get(
    `facility/list/authorized?division_uuid=${division_uuid}`,
  );
  return data;
};

export const getIssueForUuid = async ({
  uuid,
}: {
  uuid: string;
}): Promise<Issue> => {
  const { data } = await API_TOUCHECK.get(`issue/${uuid}?account_type=admin`);
  return data;
};

export const getIssues = async (params: IssueListRes): Promise<ListIssue> => {
  const { data } = await API_TOUCHECK.get(
    `issue/list?${stringify(
      {
        ...params,
      },
      {
        skipNulls: true,
        arrayFormat: 'repeat',
        encode: false,
      },
    )}`,
  );
  return data;
};
export const updateIssue = async ({
  uuid,
  status,
  account_type,
}: UpdateIssueReq & { uuid: string }): Promise<Issue> => {
  const { data } = await API_TOUCHECK.put(`issue/${uuid}`, {
    status,
    account_type,
  });
  return data;
};
export const deleteIssue = async ({
  uuid,
  account_type,
}: DeleteIssueReq & { uuid: string }): Promise<boolean> => {
  const { data } = await API_TOUCHECK.delete(`issue/${uuid}`, {
    data: {
      account_type,
    },
  });
  return data;
};

export const createMemoInIssue = async ({
  uuid,
  body,
}: {
  uuid: string;
  body: CreateIssueMemoReq;
}): Promise<IssueMemo> => {
  const { data } = await API_TOUCHECK.post(`issue/${uuid}/memo`, {
    ...body,
  });
  return data;
};

export const updateMemoInIssue = async ({
  uuid,
  memo_id,
  body,
}: {
  uuid: string;
  memo_id: number;
  body: CreateIssueMemoReq;
}): Promise<IssueMemo> => {
  const { data } = await API_TOUCHECK.put(`issue/${uuid}/memo/${memo_id}`, {
    ...body,
  });
  return data;
};
export const deleteMemoInIssue = async ({
  uuid,
  memo_id,
  body,
}: {
  uuid: string;
  memo_id: number;
  body: DeleteIssueMemoReq;
}): Promise<boolean> => {
  const { data } = await API_TOUCHECK.delete(`issue/${uuid}/memo/${memo_id}`, {
    data: {
      ...body,
    },
  });
  return data;
};

// checklist results
export const deleteCheckResult = async (uuid: string): Promise<boolean> => {
  const { data } = await API_TOUCHECK.delete(
    `check_result/${uuid}?account_type=admin`,
  );
  return data;
};

export const getCheckResult = async (uuid: string): Promise<CheckResult> => {
  const { data } = await API_TOUCHECK.get(
    `check_result/${uuid}?account_type=admin`,
  );
  return data;
};

export const getCheckResults = async ({
  account_type = 'admin',
  page,
  per_page,
  f_facility_uuids,
  f_worker_uuids,
  f_check_list_uuids,
  f_schedule_uuids,
  from_date,
  to_date,
}: {
  account_type?: string;
  page: number;
  per_page: number;
  f_facility_uuids?: string[];
  f_worker_uuids?: string[];
  f_check_list_uuids?: string[];
  f_schedule_uuids?: string[];
  from_date?: string;
  to_date?: string;
}): Promise<WorkerScheduleList> => {
  const { data } = await API_TOUCHECK.get(
    `schedule/worker-schedule/list/completed?${stringify(
      {
        account_type,
        page,
        per_page,
        facility_uuids: f_facility_uuids,
        done_worker_uuids: f_worker_uuids,
        f_check_list_uuids,
        schedule_uuids: f_schedule_uuids,
        from_date,
        to_date,
      },
      {
        skipNulls: true,
        arrayFormat: 'repeat',
        encode: false,
      },
    )}`,
  );
  return data;
};

export const createFacilitySticker = async (
  body: CreateStickerRequest,
): Promise<StickerRequest> => {
  const { data } = await API_TOUCHECK.post(`facility/sticker`, {
    ...body,
  });
  return data;
};

export const getFacilityStickerList = async ({
  uuid,
  page,
  per_page,
}: {
  uuid: string;
  page: number;
  per_page: number;
}): Promise<ListStickerItem> => {
  const { data } = await API_TOUCHECK.get(
    `facility/${uuid}/sticker/list?page=${page}&per_page=${per_page}`,
  );
  return data;
};

export const downloadCheckResult = async (params: {
  f_facility_uuids?: string[];
  f_uuids?: string[];
  f_worker_uuids?: string[];
  f_check_list_uuids?: string[];
  f_schedule_uuids?: string[];
  f_worker_schedule_uuids?: string[];
  from_date?: string;
  to_date?: string;
}): Promise<string> => {
  const { data } = await API_TOUCHECK.get(
    `check_result/download?${stringify(params, {
      skipNulls: true,
      arrayFormat: 'repeat',
      encode: false,
    })}`,
  );

  return data;
};

export const getScheduleCalendar = async ({
  from_date,
  to_date,
  status,
  facility_uuids,
  schedule_uuids,
  worker_uuids,
}: {
  from_date: string;
  to_date: string;
  status?: string[];
  facility_uuids?: string[];
  schedule_uuids?: string[];
  worker_uuids?: string[];
}): Promise<{ [key: string]: WorkerSchedule[] }> => {
  const { data } = await API_TOUCHECK.get(
    `schedule/worker-schedule/list?${stringify(
      {
        account_type: 'admin',
        from_date,
        to_date,
        status,
        facility_uuids,
        schedule_uuids,
        worker_uuids,
      },
      {
        skipNulls: true,
        arrayFormat: 'repeat',
        encode: false,
      },
    )}`,
  );
  return data;
};

export const getWorkerSchedule = async ({
  uuid,
}: {
  uuid: string;
}): Promise<WorkerSchedule> => {
  const { data } = await API_TOUCHECK.get(
    `schedule/worker-schedule/${uuid}?account_type=admin`,
  );
  return data;
};

export const deleteWorkerSchedule = async ({
  uuid,
}: {
  uuid: string;
}): Promise<boolean> => {
  const { data } = await API_TOUCHECK.delete(
    `schedule/worker-schedule/${uuid}`,
  );
  return data;
};

export const getScheduleStatCheckCount = async (params: {
  from_date: string;
  to_date: string;
  f_facility_uuids?: string[];
}): Promise<CheckCountRes> => {
  const { data } = await API_TOUCHECK.get(
    `schedule/stat/check-count?${stringify(
      {
        ...params,
      },
      {
        skipNulls: true,
        arrayFormat: 'repeat',
        encode: false,
      },
    )}`,
  );
  return data;
};

export const getScheduleStatCheckRate = async (params: {
  from_date: string;
  to_date: string;
  f_facility_uuids?: string[];
}): Promise<number> => {
  const { data } = await API_TOUCHECK.get(
    `schedule/stat/check-rate?${stringify(
      {
        ...params,
      },
      {
        skipNulls: true,
        arrayFormat: 'repeat',
        encode: false,
      },
    )}`,
  );
  return data;
};

export const getScheduleStatCheckCompare = async (params: {
  from_date: string;
  to_date: string;
  f_facility_uuids?: string[];
}): Promise<DailyCountWorkerSchedule[]> => {
  const { data } = await API_TOUCHECK.get(
    `schedule/stat/check-compare?${stringify(
      {
        ...params,
      },
      {
        skipNulls: true,
        arrayFormat: 'repeat',
        encode: false,
      },
    )}`,
  );
  return data;
};
export const getScheduleStatBestWorker = async (params: {
  from_date: string;
  to_date: string;
}): Promise<BestWorkerRes[]> => {
  const { data } = await API_TOUCHECK.get(
    `schedule/stat/best-worker?${stringify(
      {
        ...params,
      },
      {
        skipNulls: true,
        arrayFormat: 'repeat',
        encode: false,
      },
    )}`,
  );
  return data;
};

export const getIssueStatTotal = async (params: {
  from_date: string;
  to_date: string;
  f_facility_ids?: number[];
}): Promise<IssueCountRes> => {
  const { data } = await API_TOUCHECK.get(
    `issue/stat/total?${stringify(
      {
        ...params,
      },
      {
        skipNulls: true,
        arrayFormat: 'repeat',
        encode: false,
      },
    )}`,
  );
  return data;
};

export const getIssueStatSolved = async (params: {
  from_date: string;
  to_date: string;
  f_facility_ids?: number[];
}): Promise<number> => {
  const { data } = await API_TOUCHECK.get(
    `issue/stat/solved?${stringify(
      {
        ...params,
      },
      {
        skipNulls: true,
        arrayFormat: 'repeat',
        encode: false,
      },
    )}`,
  );
  return data;
};

export const getIssueStatDaily = async (params: {
  from_date: string;
  to_date: string;
  f_facility_uuids?: string[];
}): Promise<DailyCounts> => {
  const { data } = await API_TOUCHECK.get(
    `issue/stat/daily?${stringify(
      {
        ...params,
      },
      {
        skipNulls: true,
        arrayFormat: 'repeat',
        encode: false,
      },
    )}`,
  );
  return data;
};

export const getIssueStatFacility = async (params: {
  from_date: string;
  to_date: string;
  f_facility_uuids?: string[];
}): Promise<ProblemFacilitiesRes[]> => {
  const { data } = await API_TOUCHECK.get(
    `issue/stat/facility?${stringify(
      {
        ...params,
      },
      {
        skipNulls: true,
        arrayFormat: 'repeat',
        encode: false,
      },
    )}`,
  );
  return data;
};

export const getDepartments = async (): Promise<Division[]> => {
  const { data } = await API_TOUCHECK.get(`account/divisions`);
  return data;
};

export const getDivisionChildren = async ({
  parent_uuid,
}: {
  parent_uuid: string;
}): Promise<Division[]> => {
  const { data } = await API_TOUCHECK.get(
    `division/children/list?parent_uuid=${parent_uuid}`,
  );
  return data;
};

export const getAuthorizedDivision = async ({
  account_id,
}: {
  account_id: number;
}): Promise<Division[]> => {
  const { data } = await API_TOUCHECK.get(
    `division/list/authorized?account_id=${account_id}`,
  );
  return data;
};

export const getDivisionMap = async (): Promise<Division[]> => {
  const { data } = await API_TOUCHECK.get(`division/map`);
  return data;
};
export const putDivisionMap = async (body: {
  dtos: {
    uuid: string;
    name: string;
    parent_uuid: string;
    order: number;
  }[];
}): Promise<Division[]> => {
  const { data } = await API_TOUCHECK.put(`division/list`, {
    dtos: body.dtos,
  });
  return data;
};

export const updateDepartment = async ({
  uuid,
  name,
}: {
  uuid: string;
  name: string;
}): Promise<Division> => {
  const { data } = await API_TOUCHECK.put(`account/division/${uuid}`, {
    name,
  });
  return data;
};

export const deleteDepartment = async ({
  uuid,
}: {
  uuid: string;
}): Promise<Division> => {
  const { data } = await API_TOUCHECK.delete(`account/division/${uuid}`);
  return data;
};

export const createDepartment = async ({ name }: { name: string }) => {
  const { data } = await API_TOUCHECK.post(`account/division`, {
    name,
  });
  return data;
};

export const getPaymentForPeriod = async ({
  from_date,
  to_date,
}: {
  from_date: string;
  to_date: string;
}): Promise<Payment> => {
  const { data } = await API_TOUCHECK.get(
    `payment/period?from_date=${from_date}&to_date=${to_date}`,
  );
  return data;
};

export const getPayments = async ({
  page,
  per_page,
}: {
  page: number;
  per_page: number;
}): Promise<{ data: Payment[]; total: number }> => {
  const { data } = await API_TOUCHECK.get(
    `payments?page=${page}&per_page=${per_page}`,
  );
  return data;
};

export const applyTermination = async (reason: string): Promise<void> => {
  await API_TOUCHECK.post(`organization/termination?reason=${reason}`);
};

export const applyChangePlan = async () => {
  await API_TOUCHECK.post(`organization/change-plan`);
};

export const postPaymentBillingKey = async (body: {
  auth_key: string;
}): Promise<BillingKeyInfo> => {
  const { data } = await API_TOUCHECK.post(`payment/billing_key`, {
    ...body,
  });
  return data;
};

export const putPaymentBillingKey = async (body: {
  auth_key: string;
  uuid: string;
}): Promise<BillingKeyInfo> => {
  const { data } = await API_TOUCHECK.put(`payment/billing_key`, {
    ...body,
  });
  return data;
};

export const deletePaymentBillingKey = async (
  uuid: string,
): Promise<boolean> => {
  const { data } = await API_TOUCHECK.delete(
    `payment/billing_key?uuid=${uuid}`,
  );
  return data;
};

export const getPaymentBillingInfo = async (): Promise<BillingKeyInfo> => {
  const { data } = await API_TOUCHECK.get(`payment/billing_key/info`);
  return data;
};
