import { Suspense } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
// main style file
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

// theme css file
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ko';
import localeData from 'dayjs/plugin/localeData';

import useChanneltalk from '@hooks/useChanneltalk';

import SuspenseFallback from '@components/fallback/SuspenseFallback';

import './App.css';
import './apps/toucheck/checks/pages/calendar/index.css';
import { baseRoutes } from './routes';

function App() {
  const {
    i18n: { language },
  } = useTranslation();

  dayjs.extend(localeData);
  dayjs.locale(language);

  useChanneltalk();

  return (
    <ErrorBoundary
      onError={(e) => {
        console.error(e);
      }}
      fallbackRender={() => (
        <div>오류가 발생했습니다. 새로고침을 눌러주세요.</div>
      )}
    >
      <Suspense fallback={<SuspenseFallback />}>
        <RouterProvider router={baseRoutes}></RouterProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
