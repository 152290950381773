const CheckEditIcon = ({ color = '#8A92A6' }: { color?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.4813 11.5192C17.4151 12.4531 18.6028 13.1881 19.5434 13.6878L13.464 19.7672C13.0868 20.1445 12.5973 20.3892 12.0692 20.4647L8.4076 20.9877C7.59392 21.104 6.89648 20.4065 7.01272 19.5929L7.5358 15.9313C7.61125 15.4031 7.85598 14.9137 8.23324 14.5364L14.3126 8.45703C14.8123 9.39764 15.5474 10.5853 16.4813 11.5192ZM12.8667 22.6005C12.4248 22.6005 12.0667 22.9586 12.0667 23.4005C12.0667 23.8423 12.4248 24.2005 12.8667 24.2005H22.2C22.6418 24.2005 23 23.8423 23 23.4005C23 22.9586 22.6418 22.6005 22.2 22.6005H12.8667Z'
        fill={color}
      />
      <path
        d='M17.0469 5.72222C18.0099 4.75926 19.5711 4.75926 20.5341 5.72222L22.2777 7.46582C23.2407 8.42879 23.2407 9.99006 22.2777 10.953L20.7318 12.499C20.6104 12.4389 20.4827 12.3739 20.3503 12.3042C19.4592 11.835 18.4018 11.1766 17.6126 10.3874C16.8234 9.59821 16.1649 8.54075 15.6958 7.64963C15.6261 7.51722 15.5611 7.38952 15.501 7.26815L17.0469 5.72222Z'
        fill={color}
        fillOpacity='0.4'
      />
    </svg>
  );
};

export default CheckEditIcon;
