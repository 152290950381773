const NavInspection = ({ fill }: { fill: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        opacity='0.4'
        d='M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z'
        fill={fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.07996 6.65039V6.66039C7.64896 6.66039 7.29996 7.01039 7.29996 7.44039C7.29996 7.87039 7.64896 8.22039 8.07996 8.22039H11.069C11.5 8.22039 11.85 7.87039 11.85 7.42939C11.85 7.00039 11.5 6.65039 11.069 6.65039H8.07996ZM15.92 12.7404H8.07996C7.64896 12.7404 7.29996 12.3904 7.29996 11.9604C7.29996 11.5304 7.64896 11.1794 8.07996 11.1794H15.92C16.35 11.1794 16.7 11.5304 16.7 11.9604C16.7 12.3904 16.35 12.7404 15.92 12.7404ZM15.92 17.3104H8.07996C7.77996 17.3504 7.48996 17.2004 7.32996 16.9504C7.16996 16.6904 7.16996 16.3604 7.32996 16.1104C7.48996 15.8504 7.77996 15.7104 8.07996 15.7404H15.92C16.319 15.7804 16.62 16.1204 16.62 16.5304C16.62 16.9294 16.319 17.2704 15.92 17.3104Z'
        fill={fill}
      />
    </svg>
  );
};

export default NavInspection;
