import { ToastContainer } from 'react-toastify';

const Toast = () => {
  return (
    <ToastContainer
      pauseOnFocusLoss={false}
      autoClose={3000}
      position='top-center'
      hideProgressBar={true}
      closeButton={false}
      pauseOnHover={true}
    />
  );
};

export default Toast;
