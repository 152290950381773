/* tslint:disable */
/* eslint-disable */
/**
 * touch_check API
 *  touch_check API Document. 
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ScheduleDomainValuesDayCountType = {
    Count: 'count',
    Time: 'time'
} as const;

export type ScheduleDomainValuesDayCountType = typeof ScheduleDomainValuesDayCountType[keyof typeof ScheduleDomainValuesDayCountType];



