import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AxiosError } from 'axios';
import { useRecoilValue } from 'recoil';

import { toucheckAdminState } from '@context/atom';

import BackHeader from '@components/head/BackHeader';
import Loader from '@components/loader/Loader';
import Dialog from '@components/popup/dialog/SimpleDialog';
import SimpleDialog from '@components/popup/modal/toucheck/SimpleDialog';

import EnrollAnswerDeleteIcon from '@assets/EnrollAnswerDeleteIcon';

import useDeleteFacility from '../../hooks/useDeleteFacility';
import useGetFacility from '../../hooks/useGetFacility';
import FacilityInfo from './components/FacilityInfo';
import FacilitySchedule from './components/FacilitySchedule';
import FacilitySticker from './components/FacilitySticker';

const FacilitiesDetailPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uuid } = useParams<{ uuid: string }>();

  const { data } = useGetFacility({ uuid: uuid ?? '' });
  const { mutate: deleteMutate, isPending } = useDeleteFacility(uuid ?? '');

  const isAdmin = useRecoilValue(toucheckAdminState);

  const [isOpen, setIsOpen] = useState(false);
  const [deleteImpossibleOpen, setDeleteImpossibleOpen] = useState(false);

  const onDelete = () => {
    deleteMutate(undefined, {
      onSuccess: () => {
        navigate('/toucheck/facilities', { replace: true });
      },
      onError: (e) => {
        if (e instanceof AxiosError) {
          if (
            e.response?.data?.error.message ===
            'cannot delete facility that is being used by schedules'
          ) {
            setDeleteImpossibleOpen(true);
          }
        }
      },
    });
  };

  return (
    <>
      <div className='sticky-header'>
        <BackHeader
          buttonBg='bg-theme_white'
          title={t('facility_detail')}
          buttonText='delete'
          button={
            isAdmin && (
              <button
                type='button'
                className='bg-theme_white toucheck-btn px-4 py-2.5 flex items-center '
                onClick={() => setIsOpen(true)}
              >
                <EnrollAnswerDeleteIcon color='#8A92A6' />

                <span className='font-bold leading-5 text-text_gray'>
                  {t('delete')}
                </span>
              </button>
            )
          }
          onClick={() => {}}
        />
      </div>
      <section className='gap-10 layout-container '>
        <FacilityInfo
          {...{
            title: data?.name ?? '',
            address: data?.address ?? '',
            creator: data?.creator_name ?? '',
            createdAt: data?.created_at ?? '',
            lng: data?.lng ?? 0,
            lat: data?.lat ?? 0,
            isAdmin: isAdmin,
            uuid,
          }}
        />
        {uuid && <FacilitySchedule uuid={uuid} />}
        {uuid && <FacilitySticker uuid={uuid} sticker={data} />}
      </section>
      <SimpleDialog
        title={t('facility_delete_title')}
        content={t('facility_delete_content')}
        isOpen={isOpen}
        onConfirm={() => {
          onDelete();
        }}
        onClose={() => {
          setIsOpen(false);
        }}
      />
      <Loader isOpen={isPending} />
      {/* 등록된 곳 있어서 삭제 실패 */}
      <Dialog
        title={t('facility_delete_no')}
        content={t('facility_delete_no_reasom')}
        isOpen={deleteImpossibleOpen}
        onClose={() => setDeleteImpossibleOpen(false)}
        theme='bg-theme_check'
      />
    </>
  );
};

export default FacilitiesDetailPage;
