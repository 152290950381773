const BackBigIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10'
      height='18'
      viewBox='0 0 10 18'
      fill='none'
    >
      <path
        d='M9 1L0.999999 9L9 17'
        stroke='#8A92A6'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default BackBigIcon;
