import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Loader from '@components/loader/Loader';

import usePostBillingKey from '../../hooks/usePostBillingKey';

const PaymentSuccessPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { mutate } = usePostBillingKey();

  useEffect(() => {
    const authKey = searchParams.get('authKey') ?? '';
    mutate(
      { auth_key: authKey },
      {
        onSuccess: () => {
          navigate('/toucheck/settings/subscribe', { replace: true });
        },
      },
    );
  }, []);

  return <Loader isOpen />;
};

export default PaymentSuccessPage;
