import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useSetRecoilState } from 'recoil';

import {
  getAccountForToucheck,
  getOrganizationMy,
} from '@services/toucheck/apiClient_toucheck';

import { toucheckAccountState, toucheckOrgState } from '@context/atom';

import { TOKEN } from '@constants/auth';

const useLoginForToucheck = () => {
  const queryClient = useQueryClient();

  const setToucheckState = useSetRecoilState(toucheckOrgState);
  const setToucheckUser = useSetRecoilState(toucheckAccountState);
  const onLoginForToucheck = async (token: string) => {
    Cookies.set(TOKEN.TOUCHECK_TOKEN, token);

    const userData = await getAccountForToucheck();

    const org = await getOrganizationMy();

    // setToucheckState();
    setToucheckUser(userData);

    setToucheckState(org);

    // queryClient.setQueryData(['toucheck', 'organization', 'my'], org);
    queryClient.setQueryData(['toucheck', 'account', 'my'], userData);
  };
  return { onLoginForToucheck };
};

export default useLoginForToucheck;
