import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useSetMaxWidth from '@hooks/useSetMaxWidth';

import { generateMap } from '@utils/map';

import DetailBoxLayout from '@components/details/DetailBoxLayout';
import DetailIconItem from '@components/details/DetailIconItem';
import ImageWithSkeleton from '@components/img/ImageWithSkeleton';
import EditModal from '@components/popup/modal/toucheck/EditModal';

import GroupEditIcon from '@assets/toucheck/department/GroupEditIcon';
import CheckEditIcon from '@assets/toucheck/schedule/CheckEditIcon';
import CheckMapIcon from '@assets/toucheck/schedule/CheckMapIcon';
import ScheduleFacIcon from '@assets/toucheck/schedule/ScheduleFacIcon';

type Props = {
  title: string;
  address: string;
  creator: string;
  createdAt: string;
  lng: number;
  lat: number;
  isAdmin: boolean;
  uuid?: string;
};

const FacilityInfo = ({
  title,
  address,
  creator,
  createdAt,
  lat,
  lng,
  isAdmin,
  uuid,
}: Props) => {
  const { t } = useTranslation();
  const refs = useSetMaxWidth();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAddress, setIsOpenAddress] = useState(false);
  return (
    <>
      <DetailBoxLayout title={title} theme='bg-theme_check'>
        <DetailIconItem
          title={t('checklist_facility_name')}
          content={title}
          icon={<ScheduleFacIcon />}
          ref={(el) => (refs.current[0] = el)}
          popover={
            isAdmin ? (
              <div className='cursor-pointer' onClick={() => setIsOpen(true)}>
                <GroupEditIcon />
              </div>
            ) : undefined
          }
        />
        <DetailIconItem
          title={t('address')}
          content={address || t('check_empty_text')}
          icon={<CheckMapIcon />}
          ref={(el) => (refs.current[1] = el)}
          popover={
            isAdmin ? (
              <div
                className='cursor-pointer'
                onClick={() => setIsOpenAddress(true)}
              >
                <GroupEditIcon />
              </div>
            ) : undefined
          }
        />
        <DetailIconItem
          title={t('check_facility_creator')}
          content={
            <span>
              {creator}{' '}
              <span className='text-text_grayscale2'>({createdAt})</span>
            </span>
          }
          icon={<CheckEditIcon color='#00BD62' />}
          isBorder={false}
          ref={(el) => (refs.current[2] = el)}
        />
        {address && (
          <>
            <div className='h-10' />
            <ImageWithSkeleton
              src={
                generateMap({
                  size: '1256x400',
                  lat: lat.toString(),
                  lng: lng.toString(),
                }) ?? ''
              }
              alt='map'
              className='rounded-20'
            />
          </>
        )}
      </DetailBoxLayout>
      <EditModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        defaultValue={{
          name: title,
          address,
        }}
        title={t('check_facility_edit_name')}
        name='name'
        uuid={uuid}
      />
      <EditModal
        isOpen={isOpenAddress}
        onClose={() => setIsOpenAddress(false)}
        defaultValue={{
          name: title,
          address,
        }}
        title={t('check_facility_edit_address')}
        name='address'
        uuid={uuid}
      />
    </>
  );
};

export default FacilityInfo;
