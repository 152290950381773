import DefaultFallback from '@components/fallback/DefaultFallback';
import Toast from '@components/popup/toast/Toast';

const UserLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='min-h-screen bg-white'>
      <DefaultFallback>{children}</DefaultFallback>
      <Toast />
    </div>
  );
};

export default UserLayout;
