import axios, { AxiosError } from 'axios';
import Cookie from 'js-cookie';

import { TOKEN } from '@constants/auth';

const API_RELIEF = axios.create({
  baseURL: import.meta.env.VITE_BASE_RELIEFMAP_API,
  timeout: 30000,
});

const getAuthorization = (): string => {
  const accessToken = Cookie.get(TOKEN.RELIEFMAP_TOKEN) || null;
  return `Bearer ${accessToken}`;
};

const handlRefreshToken = async () => {
  const firebase = Cookie.get('firebase');
  // const firebase = '';

  try {
    const { data } = await API_RELIEF.post(
      `auth/firebase?firebase_token=${firebase}`,
    );
    Cookie.set(TOKEN.RELIEFMAP_TOKEN, data.access_token);
  } catch (e) {
    Cookie.remove(TOKEN.RELIEFMAP_TOKEN);
    Cookie.remove('firebase');

    window.location.href = `${import.meta.env.BASE_URL}user/login`;
  }
};

API_RELIEF.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'application/json; charset=utf-8';
    config.headers['Authorization'] = getAuthorization();
    config.headers['Access-Control-Allow-Credentials'] = true;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

API_RELIEF.interceptors.response.use(
  (res) => {
    return res;
  },
  async (error) => {
    const config = error.config;
    if (error instanceof AxiosError) {
      if (error.response?.status === 400) {
        if (error.response?.data.error.code === 422) {
          await handlRefreshToken();
          config.headers['Authorization'] = getAuthorization();
          return axios(config);
        }
        // 로그인 화면으로 보냄
      }
    }
    return Promise.reject(error);
  },
);

export default API_RELIEF;
