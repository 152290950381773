/* tslint:disable */
/* eslint-disable */
/**
 * touch_check API
 *  touch_check API Document.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ScheduleDomainValuesCycleType = {
  Daily: 'daily',
  Weekly: 'weekly',
  Monthly: 'monthly',
  Anytime: 'anytime',
} as const;

export type ScheduleDomainValuesCycleType =
  (typeof ScheduleDomainValuesCycleType)[keyof typeof ScheduleDomainValuesCycleType];
