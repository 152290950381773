import { useTranslation } from 'react-i18next';

const NotAllwoed = () => {
  const { t } = useTranslation();
  return (
    <div className='flex items-center justify-center h-full font-bold text-text_gray'>
      {t('only_admin_page')}
    </div>
  );
};

export default NotAllwoed;
