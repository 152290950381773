import { useEffect, useRef } from 'react';

type Props = {
  gap?: number;
  max?: number;
  dependency?: unknown;
};

const useSetMaxWidth = ({ gap, max, dependency }: Props = {}) => {
  const refs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    refs.current.forEach((ref) => {
      if (ref) {
        ref.style.maxWidth = '';
        ref.style.minWidth = '';
      }
    });
    // 각 ref의 너비를 측정
    const widths = refs.current.map((ref) => ref?.offsetWidth || 0);
    const maxTextWidth = Math.max(...widths) + (gap || 20);

    const maxWidth = maxTextWidth > 160 ? maxTextWidth : max || 160;

    // 각 요소에 max-width와 min-width를 직접 설정
    refs.current.forEach((ref) => {
      if (ref) {
        ref.style.maxWidth = `${maxWidth}px`;
        ref.style.minWidth = `${maxWidth}px`;
      }
    });
  }, [dependency, gap, max]);
  return refs;
};

export default useSetMaxWidth;
