import { NotificationType } from '@models/notification/notification';
import API from '@services/axios';
import { useQuery } from '@tanstack/react-query';

const useGetNotifications = ({
  page,
  per_page,
  enabled = true,
}: {
  page: number;
  per_page: number;
  enabled?: boolean;
}) => {
  const fetchApi = async ({
    page,
  }: {
    page: number;
  }): Promise<NotificationType[]> => {
    const { data } = await API.get(
      `notifications?page=${page}&per_page=${per_page}`,
    );
    return data;
  };
  return useQuery({
    queryKey: ['notifications'],
    queryFn: () => fetchApi({ page }),
    enabled,
  });
};

export default useGetNotifications;
