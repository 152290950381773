import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AnimatePresence, motion, useAnimate } from 'framer-motion';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { emergencyState } from '@context/atom';

import ReactPortal from '@components/ReactPortal';

import AlarmIcon from '@assets/AlarmIcon';
import EmergencyIcon from '@assets/EmergencyIcon';
import ReportAddressIcon from '@assets/ReportAddressIcon';
import ReportNumber from '@assets/ReportNumber';
import ReportPositionIcon from '@assets/ReportPositionIcon';
import ReportTime from '@assets/ReportTime';

import BasicDialog from './BasicDialog';

const AlertDialog = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [scope, animate] = useAnimate();
  const [isHide, setIsHide] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const state = useRecoilValue(emergencyState);

  const reset = useResetRecoilState(emergencyState);

  const showOrHide = {
    hidden: {
      x: '100%',
    },
    visible: {
      x: 0,
    },
    exit: {
      x: '100%',
    },
  };
  const onClickHide = () => {
    if (!isHide) {
      animate(scope.current, { x: '82%' });
      setIsHide(true);
    }
  };
  const onClickShow = () => {
    if (isHide) {
      animate(scope.current, { x: 0 });
      setIsHide(false);
    }
  };
  const onCheck = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    const reportId = state?.url.split('report/')[1];
    setIsOpen(false);
    reset();
    navigate(`/touchsiren/report/${reportId}`);
  };
  return (
    <>
      <ReactPortal wrapperId='react-portal'>
        <AnimatePresence>
          {state?.isOpen && (
            <motion.div
              ref={scope}
              variants={showOrHide}
              initial='hidden'
              animate='visible'
              exit='exit'
              transition={{ type: 'tween' }}
              className='fixed right-0 flex items-center gap-10 px-10 py-5 text-white bg-black top-32 rounded-s-20'
            >
              <div className='flex items-center gap-5'>
                <div onClick={onClickShow} className='cursor-pointer'>
                  <AlarmIcon />
                </div>
                <span className='text-lg font-bold'>
                  {t('emergency_alert_title')}
                </span>
              </div>
              <div className='flex items-center gap-5'>
                <button
                  onClick={onClickHide}
                  className='px-4 text-lg font-bold leading-10 bg-white/50 rounded-10'
                >
                  {t('emergency_alert_hidden')}
                </button>
                <button
                  onClick={onCheck}
                  className='px-4 text-lg font-bold leading-10 bg-white/50 rounded-10'
                >
                  {t('confirm')}
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </ReactPortal>
      <BasicDialog isModal title='' isOpen={isOpen} onClose={onClose}>
        <div className='flex flex-col items-center w-full gap-20'>
          <div className='flex flex-col items-center w-full gap-7.5'>
            <EmergencyIcon />
            <h2 className='text-3xl font-bold text-center'>
              {t('emergency_alert_title')}
            </h2>
          </div>
          <div className='flex flex-col w-7/12 gap-10'>
            <CellItem
              title={t('emergency_alert_id')}
              content={state?.unique_id || ''}
              icon={<ReportNumber />}
            />
            <CellItem
              title={t('emergency_alert_address')}
              content={state?.address || ''}
              icon={<ReportAddressIcon />}
            />
            <CellItem
              title={t('emergency_alert_spot')}
              content={state?.location || ''}
              icon={<ReportPositionIcon />}
            />
            <CellItem
              title={t('emergency_alert_date')}
              content={state?.created_at || ''}
              icon={<ReportTime />}
            />
          </div>
        </div>
      </BasicDialog>
    </>
  );
};

const CellItem = ({
  icon,
  title,
  content,
}: {
  icon: React.ReactNode;
  title: string;
  content: string;
}) => {
  return (
    <div className='flex items-center justify-between w-full gap-5 '>
      <div className='w-2/5 flex items-center gap-2.5'>
        {icon}
        <div className='text-xl text-text_gray'>{title}</div>
      </div>
      <div className='w-3/5 text-xl'>{content}</div>
    </div>
  );
};

export default AlertDialog;
