import { Outlet } from 'react-router-dom';

import { isAfter } from 'date-fns';

import useGetOrganization from '@apps/toucheck/settings/coporation/hooks/useGetOrganization';

import { SwitchCase } from '@components/Utils/SwitchCase';

const PlanRoute = () => {
  const { data } = useGetOrganization();
  const isEndTrial = data?.free_end_at
    ? isAfter(new Date(), new Date(data?.free_end_at))
    : false;

  return (
    <SwitchCase
      value={data?.plan ?? ''}
      defaultComponent={<Outlet />}
      caseBy={{
        enterprise: data?.is_pay_ok ? (
          <Outlet />
        ) : (
          <div className='flex items-center justify-center h-full font-bold text-center whitespace-pre-line text-text_gray'>
            {`등록한 결제 수단에 문제가 있어요\n.결제 수단을 변경 후 이용해 주세요.`}
          </div>
        ),
        standard: data?.is_pay_ok ? (
          <Outlet />
        ) : (
          <div className='flex items-center justify-center h-full font-bold whitespace-pre-line text-text_gray leading-7.5'>
            {`등록한 결제 수단에 문제가 있어요\n결제 수단을 변경 후 이용해 주세요`}
          </div>
        ),
        free: isEndTrial ? (
          <div className='flex items-center justify-center h-full font-bold text-text_gray'>
            무료 체험 기간이 종료되었습니다.
          </div>
        ) : (
          <Outlet />
        ),
        none: (
          <div className='flex items-center justify-center h-full font-bold text-text_gray'>
            사용 중인 플랜이 없어 볼 수 없어요.
          </div>
        ),
      }}
    />
  );
};

export default PlanRoute;
