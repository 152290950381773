import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useSetRecoilState } from 'recoil';

import { getMyAccountForRelief, getMyOrgForRelief } from '@services/apiClient';

import { reliefmapAccountState, reliefmapOrgState } from '@context/atom';

import { TOKEN } from '@constants/auth';

const useLoginForReliefmap = () => {
  const queryClient = useQueryClient();
  const setReliefUser = useSetRecoilState(reliefmapAccountState);
  const setReliefState = useSetRecoilState(reliefmapOrgState);
  const onLogin = async (token: string) => {
    Cookies.set(TOKEN.RELIEFMAP_TOKEN, token);
    const org = await getMyOrgForRelief();
    const userData = await getMyAccountForRelief();
    setReliefUser(userData);
    setReliefState(org);
    queryClient.setQueryData(['reliefmap', 'org', 'my'], org);
    queryClient.setQueryData(['reliefmap', 'account', 'me'], userData);
  };
  return { onLoginForReliefmap: onLogin };
};

export default useLoginForReliefmap;
