import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useSetRecoilState } from 'recoil';

import { getAccountMe, getOrganization } from '@services/apiClient';

import { touchsirenAccountState, touchsirenOrgState } from '@context/atom';

import useInitMessage from '@hooks/useInitMessage';

import { TOKEN } from '@constants/auth';

const useLoginForTouchsiren = () => {
  const queryClient = useQueryClient();
  const setUser = useSetRecoilState(touchsirenAccountState);
  const setAppState = useSetRecoilState(touchsirenOrgState);

  const { initFirebaseMessage } = useInitMessage();
  const onLoginForTouchsiren = async (token: string) => {
    Cookies.set(TOKEN.TOUCHSIREN_TOKEN, token);
    initFirebaseMessage();

    const userData = await getAccountMe();
    const organization = await getOrganization();
    setUser(userData);
    setAppState(organization);
    queryClient.setQueryData(['touchsiren', 'account', 'me'], userData);
    queryClient.setQueryData(
      ['touchsiren', 'organization', 'my'],
      organization,
    );
  };

  return { onLoginForTouchsiren };
};

export default useLoginForTouchsiren;
