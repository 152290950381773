import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Facility, StickerRequestItem } from '@/model/touchcheck';
import { ColumnDef } from '@tanstack/react-table';
import { useSetRecoilState } from 'recoil';

import useFacilitySticker from '@apps/toucheck/facility/hooks/useFacilitySticker';
import TableType2 from '@apps/toucheck/group/department/pages/details/components/TableType2';

import { facilityStickerState } from '@context/toucheck/facility';

import DetailBoxLayout from '@components/details/DetailBoxLayout';

import ApplyStickerIcon from '@assets/toucheck/ApplyStickerIcon';

type Props = {
  uuid: string;
  sticker?: Facility;
};

const FacilitySticker = ({ uuid, sticker }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useFacilitySticker(uuid);
  const setSticker = useSetRecoilState(facilityStickerState);
  const columns = useMemo<ColumnDef<StickerRequestItem>[]>(
    () => [
      {
        accessorKey: 'count',
        header: t('quantity'),
        cell: (info) =>
          t('check_facility_count', {
            count: (info.getValue() as number) ?? 0,
          }),
      },
      {
        accessorKey: 'created_at',
        header: t('sticker_date'),
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'creator_name',
        header: t('sticker_apply'),
        cell: (info) => info.getValue(),
      },
    ],
    [],
  );
  const onApplySticker = () => {
    if (sticker) {
      setSticker([sticker]);
      navigate('/toucheck/facilities/sticker');
    }
  };
  return (
    <DetailBoxLayout
      title={t('sticker_log')}
      theme='bg-theme_check'
      trans={
        <Trans
          t={t}
          i18nKey='check_facility_count_trans'
          values={{ count: 0 }}
          components={{
            1: <mark className='bg-transparent text-theme_check' />,
          }}
        />
      }
      button={
        <button
          onClick={onApplySticker}
          className='bg-theme_check px-5 py-1.5 flex items-center gap-1.5 rounded-10 shadow-basic disabled:bg-theme_check_disabled transition-all'
        >
          <ApplyStickerIcon color='#00BD62' />
          <span className='font-bold leading-7.5 text-white'>
            {t('facility_sticker')}
          </span>
        </button>
      }
    >
      <TableType2
        datas={data?.data ?? []}
        total={data?.total ?? 0}
        columns={columns}
        emptyText={t('empty_schedules')}
      />
    </DetailBoxLayout>
  );
};

export default FacilitySticker;
