const NavReport = ({ fill }: { fill: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        opacity='0.4'
        d='M12.0865 22C11.9627 22 11.8388 21.9716 11.7271 21.9137L8.12599 20.0496C7.10415 19.5201 6.30481 18.9259 5.68063 18.2336C4.31449 16.7195 3.5544 14.776 3.54232 12.7599L3.50004 6.12426C3.495 5.35842 3.98931 4.67103 4.72826 4.41215L11.3405 2.10679C11.7331 1.96656 12.1711 1.9646 12.5707 2.09992L19.2081 4.32684C19.9511 4.57493 20.4535 5.25742 20.4575 6.02228L20.4998 12.6628C20.5129 14.676 19.779 16.6274 18.434 18.1581C17.8168 18.8602 17.0245 19.4632 16.0128 20.0025L12.4439 21.9088C12.3331 21.9686 12.2103 21.999 12.0865 22Z'
        fill={fill}
      />
      <path
        d='M11.3189 14.3209C11.1256 14.3219 10.9323 14.2523 10.7833 14.1091L8.86646 12.2656C8.57048 11.9793 8.56746 11.5145 8.86042 11.2262C9.15338 10.9369 9.63158 10.934 9.92857 11.2193L11.3078 12.5451L14.6753 9.22479C14.9693 8.93552 15.4475 8.93258 15.7435 9.21793C16.0405 9.50426 16.0435 9.97004 15.7505 10.2574L11.8514 14.1022C11.7045 14.2474 11.5122 14.3199 11.3189 14.3209Z'
        fill={fill}
      />
    </svg>
  );
};

export default NavReport;
